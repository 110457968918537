export class inventoryModel {


    _id: string
    inventory_id: string
    products: any
    product: any
    dealers: any
    dealer:any

}