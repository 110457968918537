import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TOKEN, URL_SERVICES } from 'src/app/config/config';
import { UserModel } from 'src/app/models/user';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-update-user',
  templateUrl: './update-user.component.html',
})
export class UpdateUserComponent implements OnInit {


  user = new UserModel();
  
  subirPdf = File;

  selectFile: File = null;
  habilitar = true;

  token = TOKEN
  url = URL_SERVICES
  user_id;
  img;
  
  alert: boolean;
 

  constructor( private route: Router,
               private userService: UserService, 
               private http:HttpClient,
               private _router: ActivatedRoute,
               ) {
                 
                }



  ngOnInit(): void {

    this.getUserClient()

      

  }

  getUserClient(){

    this._router.paramMap.subscribe( params => {

      this.user._id = params.get("id")
      
      this.userService.getUserClient(this.user._id).subscribe( ( response:any ) =>{

        this.img = response.userDB.img
      
       this.user =  response.userDB // necesita asignar el user model para poder utilizar los valores
      

               
      })

    })

  }

  updateUserCLient( f: NgForm){

    console.log(f);
    

    if(f.value.password == f.value.confirm_password){

      this.userService.putUserClient(this.user._id, this.user).subscribe( (response:any) =>{
       
        console.log(this.user);
        this.alert = true
        this.route.navigate(['/users'])

        
        
      })


      

    } else {
      this.alert = false

      
    }


  
    
  }


  onFileSelected(event){

    this.selectFile = <File>event.target.files[0];
  
    
  }
  


  upload(){
    const headers = new HttpHeaders({
      'token': this.token
    });
    
    const fd = new FormData();
  
    
    fd.append('img', this.selectFile, this.selectFile.name)
  
  
    this.http.put(`${this.url}/upload/users/` + this.user._id , fd, {
      headers
    })
    .subscribe(resp =>{
      console.log(resp);
     
  
      this.habilitar = false;
      
      this.alert = true

      location.reload()
    });
  
  }

}
