import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { categoryInterface } from 'src/app/interfaces/category';
import { productInterface } from 'src/app/interfaces/product';
import { productModel } from 'src/app/models/product';
import { CategoryService } from 'src/app/services/category.service';
import { ProductsService } from 'src/app/services/products.service';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
})
export class ProductsComponent implements OnInit {


  products: productInterface[];
  categories:any [] = []
  notification: boolean
  busqueda:any;
  fieldName:any;
  click_marca:boolean = false;
  click_group:boolean = false;
  click_sku:boolean = false;
  type:string = '';

  constructor( private categoryService: CategoryService,
               private productsService: ProductsService,
               private route: Router) { }

  ngOnInit(): void {

    this.listProducts();


  }


  listProducts(){

    this.productsService.getProducts().subscribe( (response:productInterface) => {

      this.products = response.productsDB

      console.log(this.products);
      

      response.productsDB.forEach(categories => {

        let allCategories = categories.category
        
        this.categories.push(allCategories)
       // this.products.category = this.categories        
        
        
          
        });
        
  
      
      

    })

  }

  deleteOneProduct(product: productModel){


    this.productsService.deleteProduct( product ).subscribe( (response:any) => {


      if(!response.productDeleted){

        this.notification = false

      } else {

        this.notification = true

        

        this.route.navigate(['/products'])
        
      }
      
      this.listProducts();

    }, error  => {

      let errorMessage = <any> error;

      if(errorMessage != null ){
        
        this.notification = false

      }
      

    })



  }

  realizar_busqueda(event): void {
    this.busqueda = event.valor;
    this.products.map(fila=>fila.texto_buscable = fila['brand'] + ' ' +fila['group']+ ' ' +fila['part_number'])
  }

  orderBy(campo){
    this.fieldName = campo;
    let valores:number=document.getElementsByClassName('fa fa-sort-up').length;
    this.type = valores == 1 ?'desc':'asc';
}

orderIncial(){
  this.fieldName = '';
  this.type = '';
  this.click_group = false;
  this.click_marca = false;
  this.click_sku = false;
}

}
