import { formattedError } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderService } from 'src/app/services/order.service';

@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.css']
})
export class OrderDetailComponent implements OnInit {

  products:any[] = []
  allProducts:any[] = []


  constructor(private route: Router, private router: ActivatedRoute,
              private orderService: OrderService) { }

  ngOnInit(): void {

    this.getDealer()
  }


  getDealer() {

    this.router.paramMap.subscribe( params => {

      let id = params.get("id")

      this.orderService.getOrder( id ).subscribe( (response:any) => {

       this.allProducts =  response.orderDB.products

       this.allProducts .forEach(element => {
         
        
        for ( let productsElement of element ) {

          this.products.push(productsElement)

          console.log(this.products);

          
        }
        
         
       });
       
                 
        
      })
      

        
      })
  
  }

}
