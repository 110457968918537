import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { DEALER_ID, INVENTORY_ID, TOKEN, URL_SERVICES } from '../config/config';
import { dealerModel } from '../models/dealer';

@Injectable({
  providedIn: 'root'
})
export class DealerService {

  token = TOKEN
  url = URL_SERVICES
  dealer_id = DEALER_ID
  inventory_id = INVENTORY_ID

  constructor( private http:HttpClient) { 

    
  }


  postDealer(user_id: string, route_id: string, dealer: dealerModel){
    const headers = new HttpHeaders({
      "token": this.token
    });

    return this.http.post(`${this.url}/dealer/${user_id}/${route_id}`, dealer, {headers})

  }

  getDealer(id: string){
    const headers = new HttpHeaders({
      "token": this.token
    });

    return this.http.get(`${this.url}/dealer/`+ id, {headers})

  }

  getDealers(){
    const headers = new HttpHeaders({
      "token": this.token
    });

    return this.http.get(`${this.url}/dealers`, {headers})

  }


  putDealer(id: string, dealer: dealerModel){
    const headers = new HttpHeaders({
      "token": this.token
    });

    return this.http.put(`${this.url}/dealer/`+id, dealer, {headers})

  }
  


  getInventory( id: string){
    const headers = new HttpHeaders({
      "token": this.token
    });

    return this.http.get(`${this.url}/inventory/` + id, {headers})

  }



  deleteDealer( dealer: dealerModel ){
    const headers = new HttpHeaders({
      "token": this.token
    });

    
    return this.http.delete(`${this.url}/dealer/`+ dealer._id, {headers})

  }


}
