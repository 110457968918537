<form (ngSubmit)="addDealer( f.value )" #f="ngForm"

  role="form" class="form-horizontal" >
    <!-- normal control -->
    <div class="form-group">
        <label class="col-md-3 control-label">Nombre</label>
        <div class="col-md-9">
            <input [(ngModel)]="dealer.name" name="name" #name="ngModel" required 
            type="text" class="form-control">
        </div>
        
    </div>
    <div *ngIf="name.invalid && (name.dirty || name.touched)"
    class="alert alert-danger">

    <div *ngIf="name.errors.required">
        <p class="text-danger text-right xsmall">El nombre es requerido. Please, selecionar nombre del cliente.</p>
        
    </div>
    </div>
        

    <div class="form-group">
        <label class="col-md-3 control-label">ID</label>
        <div class="col-md-9">
            <input [(ngModel)]="dealer.dealer_id" name="dealer_id" #dealer_id="ngModel" required 
            type="text" class="form-control">
        </div>
    </div>
    <div *ngIf="dealer_id.invalid && (dealer_id.dirty || dealer_id.touched)"
    class="alert alert-danger">

    <div *ngIf="dealer_id.errors.required">
        <p class="text-danger text-right xsmall">El ID del Dealer es requerido. inserir el ID del tipo Numero (123...).</p>
        
    </div>
    </div>
    

    <div class="form-group">
        <label class="col-md-3 control-label">Dirección</label>
        <div class="col-md-9">
            <input [(ngModel)]="dealer.address" name="address"  required 
            type="text" class="form-control">
        </div>
    </div>

    <div class="form-group">
        <label class="col-md-3 control-label">Ciudad</label>
        <div class="col-md-9">
            <input [(ngModel)]="dealer.city" name="city" #city="ngModel" required 
            type="text" class="form-control">
        </div>
    </div>

    <div class="form-group">
        <label class="col-md-3 control-label">Teléfono</label>
        <div class="col-md-9">
            <input [(ngModel)]="dealer.phone" name="phone" 
            type="text" class="form-control">
        </div>
    </div>

    <div class="form-group">
        <label class="col-md-3 control-label">Usuario</label>
        <div class="col-md-9">
            <select class="form-control" [(ngModel)]="dealer.user" name="user" required>
                <option *ngFor="let user of users" 
                 [value]="user._id">{{user.name}}</option>

            </select>
        </div>
    </div>


    <div class="form-group">
        <label class="col-md-3 control-label">Ruta</label>
        <div class="col-md-9">
            <select class="form-control" [(ngModel)]="dealer.route" name="route" required>
                <option *ngFor="let route of routes" 
                 [value]="route._id">{{route.number}}</option>

            </select>
        </div>
    </div>

    <div class="form-group">
        <label class="col-md-3 control-label">Categorias</label>
        <div class="col-md-9">

            <ng-select  [items]="items" [(ngModel)]="dealer.categories" name="categories" 


               bindLabel="name"


               placeholder="Selecionar categorias para ordenes especiales"


               appendTo="body"


               multiple="true"
>


    </ng-select>

        </div>
    </div>


    


    


        <div *ngIf="notification == true">
            <p class="text-success text-right xsmedio">Salvo con exito!!</p>
            
        </div>

        <div *ngIf="notification == false">
            <p class="text-danger text-right xsmedio">Paso un error, verifica se estas llenando el formulario correctamento o el dealer ID existe!!</p>
            
        </div>


        

   <!-- with hint -->


   <!--Opcao subir imagem aqui-->


    <!--<div *ngIf="notification">
        <p class="text-success text-right xsmall">Salvo con exito!!</p>
        
    </div>-->

    <button type="button" class="btn btn-danger  right " (click)="reload()">Cancelar</button>
    
    <button type="submit" class="btn btn-success m-right2 m-left right" [disabled]="f.invalid">Guardar</button>

</form>