import { RouteService } from './../../services/route.service';
import { DealerService } from './../../services/dealer.service';
import { OrderService } from 'src/app/services/order.service';
import { ProductsService } from 'src/app/services/products.service';
import { InventoryService } from 'src/app/services/inventory.service';
import { Component, Input, OnInit } from '@angular/core';
import { USER_ID } from 'src/app/config/config';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
})


export class DashboardComponent implements OnInit {

  totalInventories
  totalProducts
  totalOrders
  totalDealers
  totalRutas

  constructor(private inventoryService: InventoryService, 
    private productsService: ProductsService,
    private orderService: OrderService,
    private dealerService: DealerService,
    private routeService: RouteService
    ) {
    
    this.inventoryService.getInventories().subscribe( (response: any) =>{
      this.totalInventories = response.inventories
    })
    this.productsService.getProducts().subscribe( (response: any)=> {
      this.totalProducts = response.total
      
    })
    
    this.orderService.getOrders().subscribe( (response: any)=>{
      this.totalOrders = response.total
      
    })

    this.dealerService.getDealers().subscribe( (response: any)=> {
      this.totalDealers = response.dealers
      
    })

    this.routeService.getRoutes().subscribe( (response:any)=> {
      this.totalRutas =response.total
      
    })
   }



  ngOnInit(): void {
    
  }


  

}