

<div class="col-md-12">
    <app-jumbotron></app-jumbotron>
    <div class="panel category panel-lined panel-hovered mb20 table-responsive basic-table">
        <div class="panel-heading text-white">
            Categoria: {{ name }} | <span class="badge  badge-sm badge-info">productos</span>
        </div>
        <div class="panel-body">
            <table class="table">
                <thead>
                    <tr>
                        <th> Marca </th>
                        <th> Reted </th>
                        <th> group </th>
                        <th> SKU </th>
                        <th> Descripción </th>
                        <th class="col-lg-2">Editar</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let product of products">
                        
                        <td> {{ product.brand }}</td>
                        <td> {{ product.rated }} </td>
                        <td> {{ product.group }} </td>
                        <td> {{ product.part_number }} </td>
                        <td> {{ product.description }}</td>


                        <td>
                            <a class="btn btn-warning m-right2" [routerLink]="['/product-update/', product._id]"><i class="fa fa-edit"></i></a>
                        </td>



                        
                    </tr>
                    

                </tbody>
            </table>
        </div>
    </div>


</div>

