import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DEALER_ID } from 'src/app/config/config';
import { dealerModel } from 'src/app/models/dealer';
import { UserModel } from 'src/app/models/user';
import { DealerService } from 'src/app/services/dealer.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
})

export class HeaderComponent implements OnInit {

  img;

  user = new UserModel()

  constructor( private router: Router,
               private userService: UserService,) {
                 
               }


  ngOnInit() {
    
    this.getUser();

  }


  logOut(){
    console.log('desconectado');

    localStorage.clear();

    this.router.navigate(['/login'])
    
  }

  getUser(){

    this.userService.getUser().subscribe( (response:any) =>{
      
      this.img = response.userDB.img
      
      //this.getDealer();

      this.user = response.userDB

      return this.user

    })

  }





}
