import { Component, OnInit } from '@angular/core';
import { USER_NOMBRE } from 'src/app/config/config';

@Component({
  selector: 'app-jumbotron',
  templateUrl: './jumbotron.component.html',
})
export class JumbotronComponent implements OnInit {

  name =  USER_NOMBRE

  constructor() { 

    this.name
  }

  ngOnInit(): void {
  }

}
