import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TOKEN, URL_SERVICES } from '../config/config';
import { inventoryModel } from '../models/inventory';

@Injectable({
  providedIn: 'root'
})
export class InventoryService {

  token = TOKEN
  inventory = new inventoryModel()
  url = URL_SERVICES

  constructor( private http: HttpClient) { }




  postInventory(dealer_id: string, inventory: inventoryModel){
    const headers = new HttpHeaders({
      "token": this.token
    });

    return this.http.post(`${this.url}/inventory/${dealer_id}/`, inventory, {headers})

  }



  getInventories(){
    const headers = new HttpHeaders({
      "token": this.token
    });

    return this.http.get(`${this.url}/inventories`, {headers})

  }

  
  getOneInventory(id: string){
    const headers = new HttpHeaders({
      "token": this.token
    });

    return this.http.get(`${this.url}/inventory/` + id, {headers})

  }


  putInventory(inventory_id: string, inventory: inventoryModel){
    
    const headers = new HttpHeaders({
      "token": this.token
    });

    return this.http.put(`${this.url}/inventory/${inventory_id}/`, inventory, {headers})

  }

  deleteInventory(id:string){
    
    const headers = new HttpHeaders({
      "token": this.token
    });

    return this.http.delete(`${this.url}/inventory/` + id , {headers})

  }
}



