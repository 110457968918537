import { Component, OnInit } from '@angular/core';
import { OrderInterface } from 'src/app/interfaces/orders';
import { DealerService } from 'src/app/services/dealer.service';
import { OrderService } from 'src/app/services/order.service';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css']

})
export class OrdersComponent implements OnInit {

  orders: OrderInterface

  regularOrders:any[] = []
  specialOrders:any[] = []
  busqueda_regular:any;
  busqueda_especial:any;

  fieldName_especial:any;
  click_name_especial:boolean = false;
  click_order_especial:boolean = false;
  type_especial:string = '';

  fieldName_regular:any;
  click_name_regular:boolean = false;
  click_order_regular:boolean = false;
  type_regular:string = '';

  public valor_seleccion:string = 'regular';

   constructor( private orderService: OrderService, ) { 

    this.listOrders()
    console.log('orders');
    
  }

  ngOnInit(): void {
  }




  listOrders(){

    this.orderService.getOrders().subscribe( (response:OrderInterface) =>{

      this.orders = response.ordersDB      

      for ( let orders of response.ordersDB ) {

        if(orders.type === 'normal') {

          this.regularOrders.push( orders )
          this.regularOrders.map(fila=>fila.dealer_name = fila['dealer']['name'])
          console.log(this.regularOrders);
        }

        if(orders.type === 'especial') {

          this.specialOrders.push( orders )
          this.specialOrders.map(fila=>fila.dealer_name = fila['dealer']['name'])
          console.log('########', this.specialOrders);
        }

        
      }
      
            
      
    })


  }

  seleccion(valor){
    this.valor_seleccion = valor;
  }

  realizar_busqueda(event){
    if(this.valor_seleccion=='regular'){
      this.realizar_busqueda_regular(event);
      return ;
    }
    this.realizar_busqueda_especial(event);
  }

  realizar_busqueda_regular(event): void {
    this.busqueda_regular = event.valor;
    this.regularOrders.map(fila=>fila.texto_buscable = fila['dealer']['name'] + ' #00' +fila['n_order'])
  }

  realizar_busqueda_especial(event): void {
    this.busqueda_especial = event.valor;
    this.specialOrders.map(fila=>fila.texto_buscable = fila['dealer']['name'] + ' #00' +fila['n_order'])
  }

  orderByEspecial(campo){
    this.fieldName_especial = campo;
    let valores:number=document.getElementsByClassName('fa fa-sort-up').length;
    this.type_especial = valores == 1 ?'desc':'asc';
}

orderByRegular(campo){
  this.fieldName_regular = campo;
  let valores:number=document.getElementsByClassName('fa fa-sort-up').length;
  this.type_regular = valores == 1 ?'desc':'asc';
}

orderIncialEspecial(){
  this.fieldName_especial = '';
  this.type_especial = '';
  this.click_order_especial = false;
  this.click_name_especial = false;
}

orderIncialRegular(){
  this.fieldName_regular = '';
  this.type_regular = '';
  this.click_order_regular = false;
  this.click_name_regular = false;
}

}
