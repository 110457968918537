<div class="panel-body">
    <table class="table" aria-describedby="my-table">
        <thead>
            <tr>
                <th> <span (click)="click_name=!click_name;orderBy('dealer_name')"> Nombre <i class="fa" aria-hidden="true" [ngClass]="click_name ? 'fa-sort-up' : 'fa-sort-down'"></i></span> </th>
                <th> Tipo </th>
                <th> Fecha </th>
                <th> <span (click)="click_order=!click_order;orderBy('n_order')"> N Order <i class="fa" aria-hidden="true" [ngClass]="click_order ? 'fa-sort-up' : 'fa-sort-down'"></i></span> </th>
                <th class="col-lg-2">Acción <i class="fa-solid fa-repeat btn-repeat" aria-hidden="true" title="Orden Data Inicial" (click)="orderIncial()" ></i></th>
            </tr>
        <tbody>
            <tr *ngFor="let ordList of ordersList | filtro:'texto_buscable':busqueda | sort:type:fieldName">
                <td style="cursor: pointer;" (click)="obtenerProducto(ordList)">
                    {{ordList['dealer']?ordList['dealer']['name']:''}} </td>
                <td style="cursor: pointer;" (click)="obtenerProducto(ordList)"> <span
                        class="badge  badge-sm" [ngClass]="ordList['type']== tipoOrden.NORMAL ? 'badge-success' :'badge-info' " >
                        {{ordList['type']}} </span></td>
                <td style="cursor: pointer;" (click)="obtenerProducto(ordList)"> {{ordList['parser_date'] | date}}
                </td>
                <td style="cursor: pointer;" (click)="obtenerProducto(ordList)"> {{ordList['n_order']}}<span
                        class="badge  badge-sm badge-info"> </span></td>
                <td>
                    <a class="btn btn-primary m-right2" (click)="exportLinealCsv(ordList)"><i class="fas fa-download" aria-hidden="true"></i></a>
                </td>
            </tr>
        </tbody>
    </table>
</div>

