import { Component, OnInit } from '@angular/core';
import { NgModel } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { productModel } from 'src/app/models/product';
import { CategoryService } from 'src/app/services/category.service';
import { ProductsService } from 'src/app/services/products.service';

@Component({
  selector: 'app-product-update',
  templateUrl: './product-update.component.html',
})

export class ProductUpdateComponent implements OnInit {

  product = new productModel();
  notification: boolean;
  categories:any = []

  constructor( private productsService: ProductsService, 
               private categoryService: CategoryService,
               private router: ActivatedRoute, 
               private route: Router) { }


  ngOnInit(): void {

    this.getOneProduct();
    this.getCategories()
  }


  getCategories(){

    this.categoryService.getCategories().subscribe( (response:any) =>{

      this.categories = response.CategoriesDB

      
      
    })

  }



  getOneProduct() {

    this.router.paramMap.subscribe( params => {

      let id = params.get("id")
      
      console.log(id);
      this.productsService.getOneProduct(id).subscribe( ( resp:any ) =>{

        this.product = resp.productDB

         
        
      })
      
  

    })

  }


  async updateProduct( f: NgModel){

    
    await  this.getOneProduct()
    

    this.productsService.updateProduct(this.product._id, this.product).subscribe( (response:any) => {

      if(!response.productUpdate){

        this.notification = false

      } else {
        
        this.notification = true

        this.route.navigate(['/products'])

      console.log(response.productUpdate);
      

      }


      
    }, error => {

      let errorMessage = <any> error;

      if(errorMessage != null ){
        
        this.notification = false

      }
      

    })
      
    
    
  }

  reload(){
    this.route.navigate(['/products'])
  }
}
