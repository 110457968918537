import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { routeModel } from 'src/app/models/route';
import { RouteService } from 'src/app/services/route.service';

@Component({
  selector: 'app-route-detail',
  templateUrl: './route-detail.component.html',
})

export class RouteDetailComponent implements OnInit {


  route = new routeModel()

  dealers:any[] = []


  route_number:number
  
  constructor( private _route: Router, 
               private routeService: RouteService,
               private router: ActivatedRoute) { }

  ngOnInit(): void {

    this.getRoute()
  }



  getRoute() {

    this.router.paramMap.subscribe( params => {

      let id = params.get("id")
      
      console.log(id);
      this.routeService.getOneRoute(id).subscribe( ( resp:any ) =>{

        this.route = resp.routeDB

        this.route_number =this.route.number
        
        this.dealers = this.route.dealers
      
        
      })

    })
  }

}
