

export class routeModel {

    _id: string
    id: string
    number: number
    date: any
    created_date: string
    dealers: any
    week: number
    weeks: any
    day: number
    routesDB: any

    constructor(){

        
    }

}