<aside class="nav-wrap" id="site-nav" data-perfect-scrollbar>
    <div class="nav-head">
        <!-- site logo -->
        <a href="index.html" class="site-logo text-uppercase">
            <img [src]="this.img | imagen" alt="" class="logo_img">
        </a>
    </div>

    <!-- Site nav (vertical) -->

    <nav class="site-nav clearfix" role="navigation">
        <div class="profile clearfix mb15"> 
            <img  [src]="this.img | imagen" alt="admin-pic">
            <div class="group">
                <h5 class="name">{{ this.user_name }}</h5>
                <small class="desig text-uppercase"> Interstate</small>
            </div>
        </div>

        <!-- navigation -->
        <ul class="list-unstyled clearfix nav-list mb15">
            <li routerLinkActive="active">
                <a [routerLink]="['/dashboard']">
                    <i class="ion fa fa-dashboard"></i>
                    <span class="text">Dashboard</span>
                </a>
            </li>

            <li routerLinkActive="active">
                <a [routerLink]="['/perfil']">
                    <i class="ion fa fa-user"></i>
                    <span class="text">perfil</span>
                </a>
            </li>

            <li routerLinkActive="active">
                <a [routerLink]="['/orders']">
                    <i class="ion fa fa-check-square-o"></i>
                    <span class="text">Pedidos</span>
                </a>
            </li>

            <li routerLinkActive="active">
                <a [routerLink]="['/orders-day']">
                    <i class="ion fa fa-check-square-o"></i>
                    <span class="text">Orders Day</span>
                </a>
            </li>
            <li routerLinkActive="active">
                <a [routerLink]="['/users']">
                    <i class="ion fa fa-users"></i>
                    <span class="text">Usuarios</span>
                </a>
            </li>
            
           <li routerLinkActive="active">
                <a [routerLink]="['/dealers']">
                    <div class="text-danger"></div>
                    <i class="ion fa fa-home"></i>
                    <span class="text">Dealers</span>
                </a>
            </li>

            <li routerLinkActive="active">
                <a [routerLink]="['/categories']">
                    <i class="ion fa fa-gears"></i>
                    <span class="text">Categorias</span>
                </a>
            </li>
            <li routerLinkActive="active">
                <a [routerLink]="['/products']">
                    <i class="ion fa  fa-tag"></i>
                    <span class="text">Productos</span>
                </a>
            </li>
            <li routerLinkActive="active">
                <a [routerLink]="['/routes']">
                    <i class="ion fa fa-truck"></i>
                    <span class="text">Rutas</span>
                </a>
            </li>

            <!--<li>
                <a href="calendar.html">
                    <i class="ion ion-calendar"></i>
                    <span class="text">Calendar</span>
                    <span class="badge badge-xs badge-danger">hot</span>
                </a>
            </li>
            <li>
                <a href="material.html">
                    <i class="ion ion-icecream"></i>
                    <span class="text">Material</span>
                    <span class="badge badge-xs badge-success">new</span>
                </a>
            </li>
            
            <li>
                <a href="javascript:;">
                    <i class="ion ion-android-options"></i>
                    <span class="text">UI Kit</span>
                    <i class="arrow ion-chevron-left"></i>
                    <span class="badge badge-xs badge-info">10</span>
                </a>
                <ul class="inner-drop list-unstyled">
                    <li><a href="ui.buttons.html">Buttons</a></li>
                    <li><a href="ui.typography.html">Typography</a></li>
                    <li><a href="ui.grids.html">Grids</a></li>
                    <li><a href="ui.panels.html">Panels</a></li>
                    <li><a href="ui.notifications.html">Notifications</a></li>
                    <li><a href="ui.tabs.html">Tabs &amp; Accordion</a></li>
                    <li><a href="ui.progress-bars.html">Progress Bars</a></li>
                    <li><a href="ui.extras.html">Extras</a></li>
                </ul>
            </li>

            

            <li>
                <a href="javascript:;">
                    <i class="ion ion-stats-bars"></i>
                    <span class="text">Charts</span>
                    <i class="arrow ion-chevron-left"></i>
                </a>
                <ul class="inner-drop list-unstyled">
                    <li><a href="charts.c3.html">C3</a></li>
                    <li><a href="charts.sparklines.html">Sparklines</a></li>
                </ul>
            </li>
            <li>
                <a href="javascript:;">
                    <i class="ion ion-document-text"></i>
                    <span class="text">Forms</span>
                    <i class="arrow ion-chevron-left"></i>
                </a>
                <ul class="inner-drop list-unstyled">
                    <li><a href="forms.elements.html">Elements</a></li>
                </ul>
            </li>
            <li>
                <a href="gmap.html">
                    <i class="ion ion-map"></i>
                    <span class="text">Maps</span>
                </a>
            </li>
            <li>
                <a href="tables.html">
                    <i class="ion ion-clipboard"></i>
                    <span class="text">Tables</span>
                </a>
            </li>
            <li>
                <a href="javascript:;">
                    <i class="ion ion-printer"></i>
                    <span class="text">Pages</span>
                    <i class="arrow ion-chevron-left"></i>
                </a>
                <ul class="inner-drop list-unstyled">
                    <li><a href="pages.signin.html">Sign In</a></li>
                    <li><a href="pages.signup.html">Sign Up</a></li>
                    <li><a href="pages.forget-pass.html">Forget Pass</a></li>
                    <li><a href="pages.404.html">404</a></li>
                    <li><a href="pages.lockscreen.html">Lock Screen</a></li>
                    <li><a href="pages.timeline.html">Timeline</a></li>
                    <li><a href="pages.search.html">Search</a></li>
                    <li><a href="pages.invoice.html">Invoice</a></li>
                </ul>
            </li>-->
        </ul> <!-- #end navigation -->
    </nav>

    <!-- nav-foot -->
    <footer class="nav-foot">
        <p>2020 &copy; <span>SendTo</span></p>
    </footer>

</aside>