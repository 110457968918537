
<div class="col-md-12">
    <app-jumbotron></app-jumbotron>
    <div class="panel panel-lined panel-hovered  table-responsive basic-table">
        <div class="panel-heading btn-success text-white">
            Dealers
            
        </div>
        <div class="panel-body">
            <div class="clearfix">
                <buscador comentario="Realice su búsqueda por nombre ó Delear Id" (campo_buscar)="realizar_busqueda($event)"></buscador>
            </div>
            <table class="table" aria-hidden="true">
                <thead>
                    <tr>
                        <th>Foto</th>
                        <th><span (click)="click_name=!click_name;orderBy('name')"> Nombre <i class="fa" aria-hidden="true" [ngClass]="click_name ? 'fa-sort-up' : 'fa-sort-down'"></i></span></th>
                        <th><span (click)="click_dealer_id=!click_dealer_id;orderBy('dealer_id')"> Dealer ID <i class="fa" aria-hidden="true" [ngClass]="click_dealer_id ? 'fa-sort-up' : 'fa-sort-down'"></i></span></th>
                        <th>Telefono</th>

                        <th class="col-lg-2">Acción <i class="fa-solid fa-repeat btn-repeat" aria-hidden="true" title="Orden Data Inicial" (click)="orderIncial()" ></i></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let dealer of dealers | filtro:'texto_buscable':busqueda | sort:type:fieldName">
                        
                        <td><img src="assets/images/interstate-product.png" alt="" width="100px"></td>
                        <td> {{dealer.name}}</td>
                        <td> 
                            <label class="label label-pink mr5"> {{  dealer.dealer_id }}  </label>
                        </td>
                        <td>
                            {{ dealer.phone }}
                        </td>
                        

                        
                        
                        <td>
                            <a class="btn btn-primary m-right2" [routerLink]="['/dealer', dealer._id]"> <i class="fa fa-eye"></i></a>
                            <a class="btn btn-warning m-right2" [routerLink]="['/update-dealer', dealer._id]"><i class="fa fa-edit"></i></a>
                            <a class="btn btn-danger" href="#dealerModal-{{ dealer._id }}" data-toggle="modal"><i class="fa fa-trash"></i></a>
                        </td>



                         <!-- Modal -->
                         <div class="modal fade" id="dealerModal-{{ dealer._id }}" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">BORRAR DEALER</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                </div>
                                <div class="modal-body">
                                    ¿Está seguro de que desea eliminar el Dealer {{ dealer.name }}  | {{ dealer.dealer_id }} ?
                                    <br>
                                    <div *ngIf="notification == true" class="modal-body ">
                                        <p class="text-success  xsmedio">El producto fue borrado con exito!!</p>
                                        
                                    </div>
                                    
                                    <div *ngIf="notification == false">
                                        <p class="text-danger text-right xsmedio">Paso un error, no se puedes borrar ese producto!!</p>
                                        
                                    </div>
                                </div>
                                
                                <div class="modal-footer">
                                <button type="button" class="btn btn-danger" data-dismiss="modal">Cancelar</button>
                                <button (click)="deleteDealer( dealer )" type="button" class="btn btn-primary" >Borrar</button>
                                </div>
                            </div>
                            </div>
                        </div>


                    </tr>
                    

                </tbody>
            </table>
        </div>
    </div>


</div>

