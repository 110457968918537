
// tslint:disable-next-line: eofline
  export const URL_SERVICES = 'https://interstate-pr.herokuapp.com/api'; //  URL PROD
 // export const URL_SERVICES = 'http://localhost:3001/api'; //  URL PROD

export const TOKEN = localStorage.getItem('token');
export const USER_ID = localStorage.getItem('_id');
export const DEALER_ID = localStorage.getItem('dealer');
export const INVENTORY_ID = localStorage.getItem('inventory');

export const USER_NOMBRE = localStorage.getItem('name');


//export const TITULAR_OWNERS_ID = localStorage.getItem('owners_id');