<form (ngSubmit)="addProduct( f.value )" #f="ngForm"

  role="form" class="form-horizontal" >
    <!-- normal control -->
    <div class="form-group">
        <label class="col-md-3 control-label">Marca</label>
        <div class="col-md-9">
            <input [(ngModel)]="product.brand" name="brand" #brand="ngModel" required 
            type="text" class="form-control">
        </div>
    </div>
    <div class="form-group">
        <label class="col-md-3 control-label">Rated</label>
        <div class="col-md-9">
            <input [(ngModel)]="product.rated" name="rated" #rated="ngModel" required 
            type="text" class="form-control">
        </div>
    </div>

    <div class="form-group">
        <label class="col-md-3 control-label">Grupo</label>
        <div class="col-md-9">
            <input [(ngModel)]="product.group" name="group" #group="ngModel" required 
            type="text" class="form-control">
        </div>
    </div>
    <!-- mensajes de erros -->
    <div *ngIf="group.invalid && (group.dirty || group.touched)"
    class="alert alert-danger">

<div *ngIf="group.errors.required">
    <p class="text-danger text-right xsmall">El nombre es requerido. Please, selecionar nombre del cliente.</p>
    
</div>
</div>

    <div class="form-group">
        <label class="col-md-3 control-label">CCA</label>
        <div class="col-md-9">
            <input [(ngModel)]="product.cca" name="cca"
            type="text" class="form-control">
        </div>
    </div>

    <div class="form-group">
        <label class="col-md-3 control-label">CA</label>
        <div class="col-md-9">
            <input [(ngModel)]="product.ca" name="ca"
            type="text" class="form-control">
        </div>
    </div>

    <!-- with hint -->
    <div class="form-group">
        <label class="col-md-3 control-label">SKU Producto</label>
        <div class="col-md-9">
            <input [(ngModel)]="product.part_number" name="part_number" #part_number="ngModel" required
            type="text" class="form-control">
        </div>
    </div>

    <!-- with hint -->
    <div class="form-group">
        <label class="col-md-3 control-label">Cantidad</label>
        <div class="col-md-9">
            <input [(ngModel)]="product.quantity" name="quantity" #quantity="ngModel" required
            type="text" class="form-control">
        </div>
    </div>

            <!-- mensajes de erros -->
            <div *ngIf="quantity.invalid && (quantity.dirty || quantity.touched)"
            class="alert alert-danger">
        
        <div *ngIf="quantity.errors.required">
            <p class="text-danger text-right xsmall">La cantidad es requerido. </p>
            
        </div>
        </div>

        <!-- mensajes de erros -->
        <div *ngIf="part_number.invalid && (part_number.dirty || part_number.touched)"
        class="alert alert-danger">
    
    <div *ngIf="part_number.errors.required">
        <p class="text-danger text-right xsmall">El part_number SKU es requerido. Please, selecionar nombre del cliente.</p>
        
    </div>
    </div>
    <div class="form-group">
        <label class="col-md-3 control-label">Categoria</label>
        <div class="col-md-9">
            <select class="form-control" [(ngModel)]="product.category" name="category" required>
                <option *ngFor="let category of categories" 
                 [value]="category._id">{{category.name}}</option>

            </select>
        </div>
    </div>

        <!-- with hint -->
        <div class="form-group">
            <label class="col-md-3 control-label">Description</label>
            <div class="col-md-9">
                <input [(ngModel)]="product.description" name="description" #description="ngModel" required
                type="text" class="form-control">
            </div>
        </div>
    
            <!-- mensajes de erros -->
            <div *ngIf="description.invalid && (description.dirty || description.touched)"
            class="alert alert-danger">
        
        <div *ngIf="description.errors.required">
            <p class="text-danger text-right xsmall">El description SKU es requerido. Please, selecionar nombre del cliente.</p>
            
        </div>
        </div>

        <div *ngIf="notification == true">
            <p class="text-success text-right xsmedio">Salvo con exito!!</p>
            
        </div>

        <div *ngIf="notification == false">
            <p class="text-danger text-right xsmedio">Paso un error, verifica se estas llenando el fdormulario correctamento!!</p>
            
        </div>


        

   <!-- with hint -->


   <!--Opcao subir imagem aqui-->


    <!--<div *ngIf="notification">
        <p class="text-success text-right xsmall">Salvo con exito!!</p>
        
    </div>-->

    <button type="button" class="btn btn-danger  right " (click)="reload()">Cancelar</button>
    
    <button type="submit" class="btn btn-success m-right2 m-left right" [disabled]="f.invalid">Guardar</button>

</form>