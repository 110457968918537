import { Component, OnInit } from '@angular/core';
import { NgModel } from '@angular/forms';
import { Router } from '@angular/router';
import { routeModel } from 'src/app/models/route';
import { PushService } from 'src/app/services/push.service';
import { RouteService } from 'src/app/services/route.service';

@Component({
  selector: 'app-add-route',
  templateUrl: './add-route.component.html',
})
export class AddRouteComponent implements OnInit {

  routes:any [] = []
  route = new routeModel()
  notification: boolean

  weeks:any[]
  daysofWeeks:any[]
  constructor( private routeService: RouteService, private _router: Router, private pushService: PushService) { 

    this.listRoutes()

    this.weeks =[{ 'semana': 0}, { 'semana': 1}, { 'semana': 2}, { 'semana': 3}, { 'semana': 4}]
    
    this.daysofWeeks = [{ 'day': 0}, { 'day': 1}, { 'day': 2}, { 'day': 3}, { 'day': 4}, { 'day': 5}, { 'day': 6}]
    

  }

  ngOnInit(): void {
    
  }


  listRoutes(){
    
    this.routeService.getRoutes().subscribe( (response:any) => {

     this.routes = response.routeDB
      
    })
  }

  addRoute( route: routeModel){


    console.log(route);

    this.routeService.postRoute(route).subscribe( response => {

    
      console.log(response);
      this.listRoutes();
      this.reload()
      
    })
    

    
  }

  async reload(){
    this._router.navigate(['/routes'])
    location.reload()

  }

}
