    <!-- dashboard page -->
    <app-jumbotron></app-jumbotron>

            <div class="row">
                <div class="col-md-3 col-sm-6">
                    <div class="panel panel-default mb20 mini-box panel-hovered">
                        <div class="panel-body">
                            <div class="clearfix">
                                <div class="info left" [routerLink]="['/dealers']">
                                    <h4 class="mt0 text-primary text-bold">{{ totalInventories }}</h4>
                                    <h5 class="text-light mb0">
                                        INVENTARIOS</h5>
                                </div>
                                <div [routerLink]="['/dealers']"
                                class="right ion fa  fa-home icon"></div>
                            </div>
                        </div>
                        <div class="panel-footer clearfix panel-footer-sm panel-footer-primary">
                            <!-- <p class="mt0 mb0 left">Inventarios disponibles {{ totalInventories }}</p> -->
                            <p class="mt0 mb0 left">Inventarios disponibles </p>
                            <span [routerLink]="['/products']"
                            class="sparkline right" data-type="bar" data-barcolor="#fff" data-width="1.15em" data-height="1.15em"><canvas width="34" height="14" style="display: inline-block; width: 34px; height: 14px; vertical-align: top;"></canvas></span>
                        </div>
                    </div>
                </div>

                <div class="col-md-3 col-sm-6">
                    <div class="panel panel-default mb20 mini-box panel-hovered">
                        <div class="panel-body">
                            <div class="clearfix">
                                <div class="info left">
                                    <h4 [routerLink]="['/orders']"
                                    class="mt0 text-success text-bold">{{ totalOrders }}</h4>
                                    <h5 class="text-light mb0">ORDENES</h5>
                                </div>
                                <div [routerLink]="['/orders']"
                                class="right ion fa  fa-check-square-o icon"></div>
                            </div>
                        </div>
                        <div class="panel-footer clearfix panel-footer-sm panel-footer-success">
                            <p class="mt0 mb0 left">¿Ver todos los pedidos?</p>
                            <span class="right sparkline" data-type="bar" data-barcolor="#fff" data-width="1.15em" data-height="1.15em"><canvas width="29" height="14" style="display: inline-block; width: 29px; height: 14px; vertical-align: top;"></canvas></span>
                        </div>
                    </div>
                </div>

                <div class="col-md-3 col-sm-6">
                    <div class="panel panel-default mb20 mini-box panel-hovered">
                        <div class="panel-body">
                            <div class="clearfix">
                                <div class="info left">
                                    <h4 class="mt0 text-info text-bold">{{ totalDealers }}</h4>
                                    <h5 class="text-light mb0"> DEALERS</h5>
                                </div>
                                <div [routerLink]="['/dealers']"
                                class="right ion  fa fa-truck"></div>
                            </div>
                        </div>
                        <div [routerLink]="['/dealers']"
                            class="panel-footer clearfix panel-footer-sm panel-footer-info">
                            <p 
                            [routerLink]="['/dealers']"class="mt0 mb0 left">Total de dealers registrados</p>
                            <span class="right sparkline" data-type="bar" data-barcolor="#fff" data-width="1.15em" data-height="1.15em"><canvas width="29" height="14" style="display: inline-block; width: 29px; height: 14px; vertical-align: top;"></canvas></span>
                        </div>
                    </div>
                </div>

                <div class="col-md-3 col-sm-6">
                    <div class="panel panel-default mb20 mini-box panel-hovered">
                        <div class="panel-body">
                            <div class="clearfix">
                                <div class="info left">
                                    <h4 class="mt0 text-warning text-bold">{{ totalRutas }}</h4>
                                    <h5 class="text-light mb0">RUTAS</h5>
                                </div>
                                <div class="right ion  fa fa-truck"></div>
                            </div>
                        </div>
                        <div [routerLink]="['/routes']"
                        class="panel-footer clearfix panel-footer-sm panel-footer-purple0" >
                            <p class="mt0 mb0 left" [routerLink]="['/routes']">Ver rutas</p>
                            <span [routerLink]="['/routes']"
                            class="sparkline right" data-type="bar" data-barcolor="#fff" data-width="1.15em" data-height="1.15em"><canvas width="29" height="14" style="display: inline-block; width: 29px; height: 14px; vertical-align: top;"></canvas></span>
                        </div>
                    </div>
                    
                </div>
                <!-- #end mini boxes -->
                
            </div>

