import { RouterModule, Routes } from '@angular/router';

import { PageComponent } from './pages/page/page.component';



import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './shared/dashboard/dashboard.component';
import { LoginGuard } from './services/login-guard.guard';
import { PerfilComponent } from './pages/perfil/perfil.component';
import { InventoriesComponent } from './components/inventories/inventories.component';
import { DealersComponent } from './components/dealers/dealers.component';
import { DealerComponent } from './components/dealers/dealer/dealer.component';
import { CategoryComponent } from './components/categories/category/category.component';
import { CategoriesComponent } from './components/categories/categories.component';
import { CategoryDetailComponent } from './components/categories/category-detail/category-detail.component';
import { UpdateCategoryComponent } from './components/categories/update-category/update-category.component';
import { AddProductComponent } from './components/products/add-product/add-product.component';
import { ProductsComponent } from './components/products/products.component';
import { ProductUpdateComponent } from './components/products/product-update/product-update.component';
import { AddRouteComponent } from './components/routes/add-route/add-route.component';
import { RoutesComponent } from './components/routes/routes.component';
import { UpdateRouteComponent } from './components/routes/update-route/update-route.component';
import { RouteDetailComponent } from './components/routes/route-detail/route-detail.component';
import { AddDealerComponent } from './components/dealers/add-dealer/add-dealer.component';
import { UpdateDealerComponent } from './components/dealers/update-dealer/update-dealer.component';
import { AddInventoryComponent } from './components/inventories/add-inventory/add-inventory.component';
import { UpdateInventoryComponent } from './components/inventories/update-inventory/update-inventory.component';
import { AddUserComponent } from './components/users/add-user/add-user.component';
import { UsersComponent } from './components/users/users.component';
import { UpdateUserComponent } from './components/users/update-user/update-user.component';
import { OrdersComponent } from './components/orders/orders.component';
import { OrderDetailComponent } from './components/orders/order-detail/order-detail.component';
import { OrderDayComponent } from './components/orders/order-day/order-day.component';




const appRoutes: Routes = [
    {
        path: '',
        component: PageComponent,
        canActivate: [LoginGuard],
        children: [
            { path: 'dashboard', component: DashboardComponent},
            { path: 'perfil', component: PerfilComponent},



            

            { path: 'dealers', component: DealersComponent},

            { path: 'inventories', component: InventoriesComponent},

            { path: 'dealer/:id', component: DealerComponent},
            { path: 'dealer', component: AddDealerComponent},
            { path: 'update-dealer/:id', component: UpdateDealerComponent},

            { path: 'category', component: CategoryComponent},
            { path: 'categories', component: CategoriesComponent},
            { path: 'category/:id', component: CategoryDetailComponent},
            { path: 'update-category/:id', component: UpdateCategoryComponent},

            { path: 'product', component: AddProductComponent},
            { path: 'products', component: ProductsComponent},
            { path: 'product-update/:id', component: ProductUpdateComponent},

            { path: 'add-route', component: AddRouteComponent},
            { path: 'routes', component: RoutesComponent},
            { path: 'update-route/:id', component: UpdateRouteComponent},
            { path: 'route/:id', component: RouteDetailComponent},

            { path: 'add-inventory', component: AddInventoryComponent},
            { path: 'inventories', component: InventoriesComponent},
            { path: 'update-inventory/:id', component: UpdateInventoryComponent},

            { path: 'add-user', component: AddUserComponent},
            { path: 'users', component: UsersComponent},
            { path: 'update-user/:id', component: UpdateUserComponent},

            { path: 'orders', component: OrdersComponent},
            { path: 'order-detail/:id', component: OrderDetailComponent},
            { path: 'orders-day', component: OrderDayComponent},



            { path: '', redirectTo: '/dashboard', pathMatch: 'full' }
        ]
    },
    { path: 'login', component: LoginComponent },


   // { path: '**', component: NopagefoundComponent }
];


export const APP_ROUTES = RouterModule.forRoot( appRoutes, { useHash: true } );
