export class UserModel {

    _id:string;
    name:string;
    email:string;
    password:string;
    confirm_password:string;
    phone:string;
    img:string;
    role:string;


    constructor(){
        this.role = 'USER_ROLE'
    }


}