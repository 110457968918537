import { Component, OnInit } from '@angular/core';
import { NgModel } from '@angular/forms';
import { Router } from '@angular/router';
import { dealerModel } from 'src/app/models/dealer';
import { CategoryService } from 'src/app/services/category.service';
import { DealerService } from 'src/app/services/dealer.service';
import { RouteService } from 'src/app/services/route.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-add-dealer',
  templateUrl: './add-dealer.component.html',

})
export class AddDealerComponent implements OnInit {

  dealer = new dealerModel()
  notification: boolean

  users: any [] = []
  routes: any [] = []
  categories: any [] = []

  items:any[]=[]




  constructor(private dealerService: DealerService,
              private userService: UserService,
              private routeService: RouteService,
              private categoryService: CategoryService,
              private route: Router) { }

  ngOnInit(): void {

    this.getUsers()
    this.getRoutes()
    this.getCategories()

  }



  addDealer(dealer: dealerModel){


    this.dealerService.postDealer(this.dealer.user, this.dealer.route, this.dealer).subscribe( (response:any) => {


      if(!response.dealerDB){

        this.notification = false

      } else {

        this.notification = true

        this.route.navigate(['/dealers'])

      }


      
    }, error => {

      let errorMessage = <any> error;

      if(errorMessage != null ){
        
        this.notification = false

        this.reload();

      }
      

    })
    

  }


  getUsers(){

    this.userService.getUsers().subscribe( ( response:any ) =>{

      this.users =  response.usersDB
      
    })

  }

  getRoutes(){

    this.routeService.getRoutes().subscribe( (response:any) =>{
      
      this.routes = response.routesDB
      
    })
  }

  getCategories(){

    this.categoryService.getCategories().subscribe( (response:any) =>{
      
      this.categories = response.CategoriesDB

      this.items = this.categories

      
      
    })
  }

  reload(){
    location.reload()
  }
}
