import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { dealerModel } from 'src/app/models/dealer';
import { CategoryService } from 'src/app/services/category.service';
import { DealerService } from 'src/app/services/dealer.service';
import { RouteService } from 'src/app/services/route.service';
import { UserService } from 'src/app/services/user.service';


@Component({
  selector: 'app-update-dealer',
  templateUrl: './update-dealer.component.html',
})
export class UpdateDealerComponent implements OnInit {


  dealer = new dealerModel()
  notification: boolean

  users: any [] = []
  routes: any [] = []
  categories: any [] = []

  items:any[]=[]




  constructor(private dealerService: DealerService,
              private userService: UserService,
              private routeService: RouteService,
              private categoryService: CategoryService,
              private router: ActivatedRoute,
              private route: Router) { }

  ngOnInit(): void {

    this.getIdDealer()
    this.getUsers()
    this.getRoutes()
    this.getCategories()


  }


  getIdDealer() {

    this.router.paramMap.subscribe( params => {

      let id = params.get("id")
      
      this.dealerService.getDealer(id).subscribe( ( resp:any ) =>{

        this.dealer = resp.dealerDB
               
      })

    })

  }


  updateDealer(dealer: dealerModel){


          
    this.dealerService.putDealer(this.dealer._id, dealer).subscribe( (response:any) => {
        
      if(!response.dealerUpdated){

        this.notification = false

      } else {

        this.notification = true

        this.route.navigate(['/dealers'])

      }


      
    }, error => {

      let errorMessage = <any> error;

      if(errorMessage != null ){
        
        this.notification = false

        this.reload();

      }
      

    })
    

  }


  getUsers(){

    this.userService.getUsers().subscribe( ( response:any ) =>{

      this.users =  response.usersDB
      
    })

  }

  getRoutes(){

    this.routeService.getRoutes().subscribe( (response:any) =>{
      
      this.routes = response.routesDB
      
    })
  }

  getCategories(){

    this.categoryService.getCategories().subscribe( (response:any) =>{
      
      this.categories = response.CategoriesDB

      this.items = this.categories     
      
      console.log(this.categories);
      
      
    })
  }

  reload(){
    
    this.route.navigate(['/dealers'])
  }
}
