
<div class="clearfix tabs-fill">
    <ul class="nav nav-tabs">
        <li class="active"><a href="#tab-flip-one-1" data-toggle="tab">INVENTARIO</a></li>
        <li><a href="#tab-flip-two-1" data-toggle="tab"> ESPECIALES</a></li>
    </ul>
    <div class="tab-content">
        <div class="tab-pane active" id="tab-flip-one-1">
            <div class="clearfix">
              
                <div class="col-md-12">
                    <div class="panel panel-lined panel-hovered mb20 table-responsive basic-table">
                        <div class="panel-heading">
                            INVENTARIO 
                              <div class="btn-group btn-group-sm right" *ngFor="let category of categories">
                                <a class="btn-tag btn-tag-primary" >{{category.name}}</a>
                            </div>
                            <div class="btn-group btn-group-sm right">
                                <a class="btn-tag btn-tag-success" > Especiales <i class="fa  fa-hand-o-right"></i></a>
                            </div>
                        </div>
                        <div class="panel-body">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th class="col-lg-1"><button type="button"  class="btn btn-default btn-sm fa  fa-check waves-effect"></button></th>
                                        <th>Foto</th>
                                        <th>Nombre</th>
                                        <th>Descripción</th>
                                        <th>Producto ID</th>
                                        <th class="col-lg-2">QTD</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let product of products">
                                        <td>
                                            <div class="ui-checkbox ui-checkbox-primary ml5">
                                                <label><input type="checkbox"><span></span>
                                                </label>
                                            </div>
                                        </td>
                                        <td><img src="assets/images/interstate-product.png" alt="" width="100px"></td>
                                        <td><a [routerLink]="['/product', product._id]">{{product.brand}}</a></td>
                                        <td>{{product.description}}</td>
                                        <td>
                                            <label class="label label-pink mr5">{{product.part_number}} </label>
                                        </td>
                                        <td> {{ product.quantity }} </td>
                                    </tr>
                                    
                
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class=" right">
                        <button [routerLink]="['/update-inventory', inventory._id]"
                         class="btn btn-success m-right"><i class="fa fa-plus-circle"></i> Editar</button>
                         <a class="btn btn-danger" href="#inventoryModal-{{ inventory._id }}" data-toggle="modal"><i class="fa fa-trash"></i></a>
                        </div>
                
                </div>


                
                 <!-- MODAL DELETE DEALER -->
                 <div class="modal fade" id="inventoryModal-{{ inventory._id }}" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">BORRAR DEALER</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        </div>
                        <div class="modal-body">
                            ¿Está seguro de que desea eliminar el inventario del Dealer: {{ dealer.name }}  ID: {{ dealer.dealer_id }} ?
                            <br>
                            <div *ngIf="notification == true" class="modal-body ">
                                <p class="text-success  xsmedio">El producto fue borrado con exito!!</p>
                                
                            </div>
                            
                            <div *ngIf="notification == false">
                                <p class="text-danger text-right xsmedio">Paso un error, no se puedes borrar ese producto!!</p>
                                
                            </div>
                        </div>
                        
                        <div class="modal-footer">
                        <button type="button" class="btn btn-danger" data-dismiss="modal">Cancelar</button>
                        <button (click)="deleteDealer( dealer )" type="button" class="btn btn-primary" >Borrar</button>
                        </div>
                    </div>
                    </div>
                </div>



                

                
            <!-- ESPECIALES-->

            </div>
        </div>
        <div class="tab-pane" id="tab-flip-two-1">

                <div class="panel panel-lined panel-hovered mb20 table-responsive basic-table">
                    <div class="panel-heading">
                        ORDENES ESPECIALES 
                          <div class="btn-group btn-group-sm right" *ngFor="let category of categories">
                            <a class="btn-tag btn-tag-primary" >{{category.name}}</a>
                        </div>
                        <div class="btn-group btn-group-sm right">
                            <a class="btn-tag btn-tag-success" >Ordenes Especiales <i class="fa  fa-hand-o-right"></i></a>
                        </div>
                    </div>
                    <div class="panel-body">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th class="col-lg-1"><button type="button"  class="btn btn-default btn-sm fa  fa-check waves-effect"></button></th>
                                    <th>Foto</th>
                                    <th>Brand</th>
                                    <th>Descripción</th>
                                    <th>Producto ID</th>
                                    <th class="col-lg-2">QTD</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let product of allProducts">
                                    <td>
                                        <div class="ui-checkbox ui-checkbox-primary ml5">
                                            <label><input type="checkbox"><span></span>
                                            </label>
                                        </div>
                                    </td>
                                    <td><img src="assets/images/interstate-product.png" alt="" width="100px"></td>
                                    <td>{{ product.brand }}</td>
                                    <td>{{ product.description }}</td>
                                    <td>
                                        <label class="label label-pink mr5">{{ product.part_number }} </label>
                                    </td>
                                    <td> {{ product.quantity }} </td>
                                </tr>
                                
            
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class=" right">
                    <button [routerLink]="['/update-dealer', dealer._id]"
                     class="btn btn-success m-right"><i class="fa fa-plus-circle"></i> Editar</button>
                </div>
            
            </div>
            
        </div>
</div>