import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { categoryModel } from 'src/app/models/category';
import { productModel } from 'src/app/models/product';
import { CategoryService } from 'src/app/services/category.service';

@Component({
  selector: 'app-category-detail',
  templateUrl: './category-detail.component.html',
})
export class CategoryDetailComponent implements OnInit {


  category = new categoryModel()
  products: any[] = []
  name;

  notification: boolean


  constructor( private route: Router, 
    private categoryService: CategoryService,
    private router: ActivatedRoute) { }

  ngOnInit(): void {

    this.getOnecCategory()
  }



  getOnecCategory() {

    this.router.paramMap.subscribe( params => {

      let id = params.get("id")
      
      console.log(id);
      this.categoryService.getCategory(id).subscribe( ( resp:any ) =>{

        this.category = resp.categoryDB
        this.products = resp.categoryDB.products
        
        console.log(this.category);

        console.log( this.products);
        this.name = this.category.name
        
        
        
        
      })

    })
  }
}
