import { NgModule } from "@angular/core";
import { FormsModule } from '@angular/forms';
import { BuscadorComponent } from "./buscador.component";

@NgModule({
    declarations: [
        BuscadorComponent
      ],
    imports:[FormsModule],
    exports: [BuscadorComponent]
    
    
  })
  export class BuscadorModule { }