import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserModel } from 'src/app/models/user';
import { UserService } from 'src/app/services/user.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TOKEN, URL_SERVICES, USER_ID } from 'src/app/config/config';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
})
export class PerfilComponent implements OnInit {

  user = new UserModel();
  


  


  subirPdf = File;

  selectFile: File = null;
  habilitar = true;

  token = TOKEN
  url = URL_SERVICES
  user_id = USER_ID
  img;
  
  alert: boolean;
 

  constructor( private router: Router,
               private userService: UserService, 
               private http:HttpClient,
               ) {
                 
                }



  ngOnInit(): void {

    
    this.userService.getUser().subscribe( (response:any) =>{

      this.img = response.userDB.img
      
      this.user =  response.userDB // necesita asignar el user model para poder utilizar los valores
      


      
    })
    


  }

  updatePerfil(f: NgForm){

    if(f.value.password == f.value.confirm_password){

      this.userService.updateUser(this.user).subscribe( (response:any) =>{
       
        console.log(this.user);
        this.alert = true
        
      })

    } else {
      console.log('es diferente');
      this.alert = false

      
    }


  
    
  }


  onFileSelected(event){

    this.selectFile = <File>event.target.files[0];
  
    
  }
  


  upload(){
    const headers = new HttpHeaders({
      'token': this.token
    });
    
    const fd = new FormData();
  
    
    fd.append('img', this.selectFile, this.selectFile.name)
  
  
  
    this.http.put(`${this.url}/upload/users/` + this.user_id , fd, {
      headers
    })
    .subscribe(resp =>{
      console.log(resp);
     
  
      this.habilitar = false;
      
      this.alert = true

      location.reload()
    });
  
  }

}
