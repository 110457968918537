<app-jumbotron></app-jumbotron>
<div class="clearfix tabs-fill">
    <ul class="nav nav-tabs">
        <li class="active"><a href="#tab-flip-one-1" data-toggle="tab" (click)="seleccion('regular')">Regular</a></li>
        <li><a href="#tab-flip-two-1" data-toggle="tab" (click)="seleccion('especial')">Especial</a></li>
    </ul>
    <div class="tab-content">
        <div class="tab-pane active" id="tab-flip-one-1">
            <div class="clearfix">
                <buscador comentario="Realice su búsqueda por dealer ó N° Order" (campo_buscar)="realizar_busqueda($event)"></buscador>
            </div>
            <div class="clearfix">
                <span class="badge badge-sm badge-success">ordenes regulares</span>
                <div class="panel-body">
                    <table class="table" aria-hidden="true">
                        <thead>
                            <tr>
                                <th> <span (click)="click_name_regular=!click_name_regular;orderByRegular('dealer_name')"> Dealer <i class="fa" aria-hidden="true" [ngClass]="click_name_regular ? 'fa-sort-up' : 'fa-sort-down'"></i></span> </th>
                                <th> Tipo </th>
                                <th> Fecha </th>
                                <th> <span (click)="click_order_regular=!click_order_regular;orderByRegular('n_order')"> N Order <i class="fa" aria-hidden="true" [ngClass]="click_order_regular ? 'fa-sort-up' : 'fa-sort-down'"></i></span> </th>
                                <th class="col-lg-2">Acción <i class="fa-solid fa-repeat btn-repeat" aria-hidden="true" title="Orden Data Inicial" (click)="orderIncialRegular()" ></i></th>
                            </tr>
                        <tbody>
                            
                            <tr *ngFor="let order of regularOrders | filtro:'texto_buscable':busqueda_regular | sort:type_regular:fieldName_regular">
                                
                                
                                <td> {{ order.dealer.name }}</td>
                                <td> <span class="badge  badge-sm badge-success"> {{ order.type }} </span></td>
                                <td> {{ order.parser_date | date}} </td>
                                <td  
                                > #00{{order.n_order}}<span class="badge  badge-sm badge-info"> </span></td>
        
        
                                <td>
                                    <a class="btn btn-primary m-right2" [routerLink]="['/order-detail/', order._id]"><i class="fa fa-eye"></i></a>

                                </td>
    
        
                            
                            </tr>
                            
        
                        </tbody>
                    </table>
                </div>
                
            </div>
        </div>
        <div class="tab-pane" id="tab-flip-two-1">
            <div class="clearfix">
                <buscador comentario="Realice su búsqueda por dealer ó N° Order" (campo_buscar)="realizar_busqueda($event)"></buscador>
            </div>
            <div class="clearfix">
                <span class="badge badge-sm badge-info">ordenes especiales</span>

                <div class="panel-body">
                    <table class="table" aria-hidden="true">
                        <thead>
                            <tr>
                                <th> <span (click)="click_name_especial=!click_name_especial;orderByEspecial('dealer_name')"> Dealer <i class="fa" aria-hidden="true" [ngClass]="click_name_especial ? 'fa-sort-up' : 'fa-sort-down'"></i></span> </th>
                                <th> Tipo </th>
                                <th> Fecha </th>
                                <th> <span (click)="click_order_especial=!click_order_especial;orderByEspecial('n_order')"> N Order <i class="fa" aria-hidden="true" [ngClass]="click_order_especial ? 'fa-sort-up' : 'fa-sort-down'"></i></span> </th>
                                <th class="col-lg-2">Acción <i class="fa-solid fa-repeat btn-repeat" aria-hidden="true" title="Orden Data Inicial" (click)="orderIncialEspecial()" ></i></th>
                            </tr>
                        <tbody>
                            
                            <tr *ngFor="let order of specialOrders | filtro:'texto_buscable':busqueda_especial | sort:type_especial:fieldName_especial">
                                
                                
                                <td> {{ order.dealer.name }}</td>
                                <td> <span class="badge  badge-sm badge-info"> {{ order.type }} </span></td>
                                <td> {{ order.parser_date | date}} </td>
                                <td  
                                > #00{{order.n_order}}<span class="badge  badge-sm badge-info"> </span></td>
        
        
                                <td>
                                    <a class="btn btn-primary m-right2" [routerLink]="['/order-detail/', order._id]"><i class="fa fa-eye"></i></a>

                                </td>
    
        
                            
                            </tr>
                            
        
                        </tbody>
                    </table>
                </div>
                
            </div>
        </div>
        
    </div>
</div>


