import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { routeModel } from 'src/app/models/route';
import { RouteService } from 'src/app/services/route.service';

@Component({
  selector: 'app-routes',
  templateUrl: './routes.component.html',
})
export class RoutesComponent implements OnInit {


  routes: any[] = []
  notification: boolean
  busqueda:any;

  fieldName:any;
  click_numero_ruta:boolean = false;
  click_cantidad_delear:boolean = false;
  type:string = '';

  constructor( private routeService: RouteService,
               private route: Router  )  { 

    this.listRoutes();

  }

  ngOnInit(): void {
  }


  listRoutes(){

    this.routeService.getRoutes().subscribe( (response:routeModel) => {

      this.routes = response.routesDB
      this.routes.map(fila=>fila.cantidad=fila.dealers.length )
      
    })
  }

  deleteRoute( route: routeModel ) {

    this.routeService.deleteRoute( route ).subscribe( (response:any) => {


      if(!response.routeDeleted){

        this.notification = false

      } else {

        this.notification = true

        this.listRoutes();

        this.route.navigate(['/routes'])
        
      }
      


    }, error  => {

      console.log(error);
      
      let errorMessage = <any> error;

      if(errorMessage != null ){
        
        this.notification = false

      }
      

    })

  }

  realizar_busqueda(event): void {
    this.busqueda = event.valor;
    this.routes.map(fila=>{fila.texto_buscable = fila['number'];fila.cantidad=fila.dealers.length} )
  }

  orderBy(campo){
    this.fieldName = campo;
    let valores:number=document.getElementsByClassName('fa fa-sort-up').length;
    this.type = valores == 1 ?'desc':'asc';
}

orderIncial(){
  this.fieldName = '';
  this.type = '';
  this.click_cantidad_delear = false;
  this.click_numero_ruta = false;
}
}
