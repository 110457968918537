<app-header></app-header>
        

<!-- main-container -->
<div class="main-container clearfix">
<app-sidebar></app-sidebar>

<div class="page page-dashboard">

<div class="content-container">
<div class="page-wrap">
    <div class="row">
        <!-- dashboard header -->
        <div class="col-md-12">
<router-outlet></router-outlet>
</div>
</div>
</div>
</div>
</div>


</div>


