import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  img;
  user_name

  constructor( private router: Router, private userService: UserService) { }


  ngOnInit(): void {
    this.userService.getUser().subscribe( (response:any) =>{
      
      this.img = response.userDB.img
      this.user_name = response.userDB.name

      
      
      
    })
  }

}
