import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject} from 'rxjs';
import { TOKEN, URL_SERVICES } from '../config/config';
import { Order } from '../models/order';

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  url = URL_SERVICES
  token = TOKEN;

  order = new Order();

  constructor( private http: HttpClient ) { }




  getOrders(){
    const headers = new HttpHeaders({
      "token": this.token
    });

    return this.http.get(`${this.url}/orders/`, {headers})

  }

  getOrder(id: string){
    const headers = new HttpHeaders({
      "token": this.token
    });

    return this.http.get(`${this.url}/order/`+ id, {headers});

  }

  getOrdersDay(order: Order){
    const headers = new HttpHeaders({
      "token": this.token
    });

    return this.http.post(`${this.url}/orders_day/`, order, {headers})

  }

}
