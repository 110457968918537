import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'buscador',
  templateUrl: './buscador.component.html',
  styleUrls: ['./buscador.component.scss']
})
export class BuscadorComponent implements OnInit {

  @Input() comentario;
  @Output() campo_buscar = new EventEmitter();
  public valor: string;

  constructor() { }

  ngOnInit() {
  }


  validar_busqueda() {
    this.campo_buscar.emit({valor: this.valor});
  }

}
