import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { dealerModel } from 'src/app/models/dealer';
import { DealerService } from 'src/app/services/dealer.service';

@Component({
  selector: 'app-dealers',
  templateUrl: './dealers.component.html',
})
export class DealersComponent implements OnInit {


  dealer = new dealerModel();

  dealers: any = [];
  notification: boolean
  busqueda:any ;
  fieldName:any;
  click_name:boolean = false;
  click_dealer_id:boolean = false;
  type:string = '';

  constructor( private dealerService: DealerService, private route: Router) { 

      this.listDealers()

  }

  ngOnInit(): void {
  }

  id_d ="60b523780f3d4704d072a78b"
  listDealers(){

    this.dealerService.getDealers().subscribe( (resp:any)=>{

      this.dealers = resp.dealersDB
      this.id_d
    
      
    })
  }



  deleteDealer( dealer: dealerModel ) {

    this.dealerService.deleteDealer( dealer ).subscribe( (response:any) => {


      if(!response.dealerDeleted){

        this.notification = false

      } else {

        this.notification = true

        this.listDealers()

        this.route.navigate(['/dealers'])
        
      }
      


    }, error  => {

      console.log(error);
      
      let errorMessage = <any> error;

      if(errorMessage != null ){
        
        this.notification = false

      }
      

    })

  }

  realizar_busqueda(event): void {
    this.busqueda = event.valor;
    this.dealers.map(fila=>fila.texto_buscable = fila['name'] + ' ' +fila['dealer_id'])
  }

  orderBy(campo){
    this.fieldName = campo;
    let valores:number=document.getElementsByClassName('fa fa-sort-up').length;
    this.type = valores == 1 ?'desc':'asc';
}

orderIncial(){
  this.fieldName = '';
  this.type = '';
  this.click_dealer_id = false;
  this.click_name = false;
}

}
