import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TOKEN, URL_SERVICES } from '../config/config';
import { routeModel } from '../models/route';

@Injectable({
  providedIn: 'root'
})
export class RouteService {

  route = new routeModel()

  token = TOKEN
  url = URL_SERVICES
  constructor( private http:HttpClient) { }


  postRoute( route: routeModel){
    
    const headers = new HttpHeaders({
      "token": this.token
    });

    return this.http.post(`${this.url}/route/`, route, {headers})

  }

  getRoutes(){
    
    const headers = new HttpHeaders({
      "token": this.token
    });

    return this.http.get(`${this.url}/routes/`, {headers})

  }


  getOneRoute( id: string,){
    const headers = new HttpHeaders({
      "token": this.token
    });

    
    return this.http.get(`${this.url}/route/`+ id, {headers})

  }

  putRoute( id: string, route: routeModel){
    const headers = new HttpHeaders({
      "token": this.token
    });

    
    return this.http.put(`${this.url}/route/`+ id, route, {headers})

  }

  deleteRoute( route: routeModel ){
    const headers = new HttpHeaders({
      "token": this.token
    });

    
    return this.http.delete(`${this.url}/route/`+ route._id, {headers})

  }

}
