import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TOKEN, URL_SERVICES } from '../config/config';
import { categoryModel } from '../models/category';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {


  token = TOKEN
  url = URL_SERVICES

  category = new categoryModel();

  constructor( private http: HttpClient) { 

    console.log('services imported');
    
  }



  postCategory( category){
    const headers = new HttpHeaders({
      "token": this.token
    });

    return this.http.post(`${this.url}/category`, category, {headers})

  }

  

  getCategories( ){
    const headers = new HttpHeaders({
      "token": this.token
    });

    return this.http.get(`${this.url}/categories`, {headers})

  }

  getCategory( id: string ){
    const headers = new HttpHeaders({
      "token": this.token
    });

    return this.http.get(`${this.url}/category/` + id, {headers})

  }


  updateCategory( id: string, category: categoryModel){
    const headers = new HttpHeaders({
      "token": this.token
    });

    
    return this.http.put(`${this.url}/category/`+ id, category, {headers})

  }

  deleteCategory(category: categoryModel){
    const headers = new HttpHeaders({
      "token": this.token
    });

    
    return this.http.delete(`${this.url}/category/`+category._id,  {headers})

  }
}


