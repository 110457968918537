import { Route } from '@angular/compiler/src/core';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { dealerModel } from 'src/app/models/dealer';
import { inventoryModel } from 'src/app/models/inventory';
import { DealerService } from 'src/app/services/dealer.service';
import { InventoryService } from 'src/app/services/inventory.service';

@Component({
  selector: 'app-dealer',
  templateUrl: './dealer.component.html',
})
export class DealerComponent implements OnInit {

  dealer = new dealerModel();
  inventory = new inventoryModel()
  
  notification: boolean

  dealerProducts:any = [];
  allProducts;

  constructor( private router: ActivatedRoute, private  dealerService: DealerService,
               private inventoryService: InventoryService, private route: Router) { 
    
    
    
    
  }

  ngOnInit(): void {


    this.router.paramMap.subscribe( params => {

      let id = params.get("id")
      
      this.dealerService.getDealer(id).subscribe( ( resp:any ) =>{

        this.dealer = resp.dealerDB

        
        this.inventory._id = this.dealer.inventory

        this.getInventory()
        
        
        for (let index = 0; index < resp.dealerDB.categories.length; index++) {
          const categories = resp.dealerDB.categories[index];   
          
          
          
          let products = categories.products

              products.forEach(products => {

              this.dealerProducts.push(products)
              
              this.allProducts = this.dealerProducts


          
          });

           
        }
        
        
        

      })

        
        
      })
  

    
  }


  products:any [] = [];
  categories:any [] = [];
  product
  getInventory() {

    this.dealerService.getInventory(this.inventory._id).subscribe( (inventoryDB:any) => {

      this.products = inventoryDB.inventoryDB.products
      this.inventory = inventoryDB.inventoryDB
      this.categories = inventoryDB.inventoryDB.dealer.categories      

      return this.products
      
      

    })

  }

  deleteDealer( dealer: dealerModel){


    this.inventoryService.deleteInventory( this.inventory._id ).subscribe( response => {

      this.route.navigate(['/dealers'])
      
    })

  }
}
