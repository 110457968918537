import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { categoryInterface } from 'src/app/interfaces/category';
import { categoryModel } from 'src/app/models/category';
import { CategoryService } from 'src/app/services/category.service';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
})
export class CategoriesComponent implements OnInit {


  categories: any
  products: any

  product:any
  allProducts:any[] = []

  category = new categoryModel()
  notification: boolean
  busqueda:any;
  fieldName:any;
  click_name:boolean = false;
  click_descripcion:boolean = false;
  type:string = '';
  
  constructor( private categoryService: CategoryService,
               private router: ActivatedRoute, 
               private route: Router) { }

  ngOnInit(): void {

    this.getCategories();


  }

  
  getCategories(){

    this.categoryService.getCategories().subscribe( (response: any) =>{

      this.categories = response.CategoriesDB

      
    })

  }


    deleteCategory(category: categoryModel){
    
    this.categoryService.deleteCategory( category ).subscribe( response =>{
   
        this.getCategories();
                 
       })
       
     }

     realizar_busqueda(event): void {
      this.busqueda = event.valor;
      this.categories.map(fila=>fila.texto_buscable = fila['name'] + ' ' +fila['description'])
    }

    orderBy(campo){
      this.fieldName = campo;
      let valores:number=document.getElementsByClassName('fa fa-sort-up').length;
      this.type = valores == 1 ?'desc':'asc';
  }
  
  orderIncial(){
    this.fieldName = '';
    this.type = '';
    this.click_descripcion = false;
    this.click_name = false;
  }

}
