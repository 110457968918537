import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { categoryModel } from 'src/app/models/category';
import { CategoryService } from 'src/app/services/category.service';

@Component({
  selector: 'app-update-category',
  templateUrl: './update-category.component.html',
})
export class UpdateCategoryComponent implements OnInit {

  category = new categoryModel();
  notification: boolean;


  constructor( private categoryService: CategoryService, 
               private router: ActivatedRoute, 
               private route: Router) { }

  ngOnInit(): void {

    this.getOneCategory()

  }


  getOneCategory(){

    this.router.paramMap.subscribe( params => {

      let id = params.get("id")
      
      this.categoryService.getCategory(id).subscribe( ( resp:any ) =>{

        this.category = resp.categoryDB

         
        
      })
  

    })
  }

 async updateCategory( f: NgForm){

 await  this.getOneCategory()

     
  await  this.categoryService.updateCategory( this.category._id, this.category ).subscribe( response =>{

     console.log( response);
      
     this.route.navigate(['/categories'])
     
    })
    
  }


  reload(){
    this.route.navigate(['/categories'])
  }

}
