

<div class="col-md-12">
    <app-jumbotron></app-jumbotron>
    <div class="panel routes panel-lined panel-hovered mb20 table-responsive basic-table">
        <div class="panel-heading text-black">
            numero de ruta: {{ route_number }} | <span class="badge  badge-sm badge-success">Dealers</span>
        </div>
        <div class="panel-body">
            <table class="table ">
                <thead>
                    <tr>
                        <th> Nombre </th>
                        <th> Dirección </th>
                        <th> Ciudad </th>
                        <th> ID </th>
                        <th class="col-lg-2">Editar</th>
                    </tr>
                </thead>
                <tbody class="bg-dealers"> 
                    <tr *ngFor="let dealer of dealers">
                        
                        <td> {{ dealer.name }}</td>
                        <td> {{ dealer.address }} </td>
                        <td> {{ dealer.city }} </td>
                        <td> {{ dealer.dealer_id }} </td>
                        


                        <td>
                            <a class="btn btn-warning m-right2" [routerLink]="['/update-dealer/', dealer._id]"><i class="fa fa-edit"></i></a>
                        </td>



                        
                    </tr>
                    

                </tbody>
            </table>
        </div>
    </div>


</div>

