<form (ngSubmit)="addCategory( f.value )" #f="ngForm"

  role="form" class="form-horizontal" >
    <!-- normal control -->
    <div class="form-group">
        <label class="col-md-3 control-label">Nombre</label>
        <div class="col-md-9">
            <input [(ngModel)]="category.name" name="name" #name="ngModel" required 
            type="text" class="form-control">
        </div>
    </div>

    <!-- mensajes de erros -->
    <div *ngIf="name.invalid && (name.dirty || name.touched)"
    class="alert alert-danger">

<div *ngIf="name.errors.required">
    <p class="text-danger text-right xsmall">El nombre es requerido. Please, selecionar nombre del cliente.</p>
    
</div>
</div>

    <!-- with hint -->
    <div class="form-group">
        <label class="col-md-3 control-label">Descripción</label>
        <div class="col-md-9">
            <input [(ngModel)]="category.description" name="description" #description="ngModel" required
            type="text" class="form-control">
        </div>
    </div>

        <!-- mensajes de erros -->
        <div *ngIf="description.invalid && (description.dirty || description.touched)"
        class="alert alert-danger">
    
    <div *ngIf="description.errors.required">
        <p class="text-danger text-right xsmall">El description es requerido. Please, selecionar nombre del cliente.</p>
        
    </div>
    </div>


    <div *ngIf="notification">
        <p class="text-success text-right xsmall">Salvo con exito!!</p>
        
    </div>

    <button type="button close" class="btn btn-danger  right " (click)="reload()">Cancelar</button>
    
    <button type="submit" class="btn btn-success m-right2 m-left right" [disabled]="f.invalid" >Guardar</button>

</form>