

<div class="col-md-12">
    <app-jumbotron></app-jumbotron>
    <div class="panel panel-lined panel-hovered  table-responsive basic-table">
        <div class="panel-heading btn-primary text-white">
            Categorias
            
        </div>
        <div class="panel-body">
            <div class="clearfix">
                <buscador comentario="Realice su búsqueda por nombre ó descripción" (campo_buscar)="realizar_busqueda($event)"></buscador>
            </div>
            <table class="table" aria-hidden="true">
                <thead>
                    <tr>
                        <th><span (click)="click_name=!click_name;orderBy('name')"> Nombre <i class="fa" aria-hidden="true" [ngClass]="click_name ? 'fa-sort-up' : 'fa-sort-down'"></i></span></th>
                        <th><span (click)="click_descripcion=!click_descripcion;orderBy('description')"> Descripción <i class="fa" aria-hidden="true" [ngClass]="click_descripcion ? 'fa-sort-up' : 'fa-sort-down'"></i></span></th>
                        <th>Fecha de Creación</th>
                        <th class="col-lg-2">Ver / Editar  / Borrar <i class="fa-solid fa-repeat btn-repeat" aria-hidden="true" title="Orden Data Inicial" (click)="orderIncial()" ></i></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let category of categories | filtro:'texto_buscable':busqueda | sort:type:fieldName">
                        
                        <td> {{category.name}}</td>
                        <td>
                            {{ category.description }}
                        </td>
                        <td> {{ category.created_date | date }}</td>


                        <td>
                            <a class="btn btn-primary m-right2" [routerLink]="['/category', category._id]"> <i class="fa fa-eye"></i></a>
                            <a class="btn btn-warning m-right2" [routerLink]="['/update-category', category._id]"><i class="fa fa-edit"></i></a>
                            <a class="btn btn-danger" href="#exampleModal-{{category._id}}" data-toggle="modal"><i class="fa fa-trash"></i></a>
                        </td>



                         <!-- Modal -->
                         <div class="modal fade" id="exampleModal-{{category._id}}" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Borrar Categoria</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                </div>
                                <div class="modal-body">
                                    ¿Está seguro de que desea eliminar esta categoría? {{ category.name}}
                                </div>
                                <div class="modal-footer">
                                <button type="button" class="btn btn-danger" data-dismiss="modal">Cancelar</button>
                                <button type="button" class="btn btn-primary" (click)="deleteCategory(category)">Borrar</button>
                                </div>
                            </div>
                            </div>
                        </div>


                    </tr>
                    

                </tbody>
            </table>
        </div>
    </div>


</div>
