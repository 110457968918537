export class productModel {

    _id: string
    part_number: string
    brand: string
    rated: string
    ca: string
    cca: string
    group: string
    description: string
    img: string
    category: string
    quantity: number

    constructor(){

        this.quantity = 1
    }

}