import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserInterface } from 'src/app/interfaces/user';
import { dealerModel } from 'src/app/models/dealer';
import { UserModel } from 'src/app/models/user';
import { DealerService } from 'src/app/services/dealer.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
})
export class UsersComponent implements OnInit {

  dealer = new dealerModel();

  dealers: any = [];
  notification: boolean

  users: UserInterface[];
  busqueda:any ;
  fieldName:any;
  click_name:boolean = false;
  click_email:boolean = false;
  type:string = '';

  constructor( private route: Router,
               private userService: UserService) { 

      this.listUsers()

  }

  ngOnInit(): void {
  }

  listUsers(){

    this.userService.getUsers().subscribe( (response: UserInterface) =>{

      this.users = response.usersDB
      
      console.log(this.users);
      
      
    })

  }



  deleteUser( user: UserModel ) {

    this.userService.deleteUser( user ).subscribe( (response:any) => {

      
      if(!response.userDeleted){

        this.notification = false

      } else {

        this.notification = true

        this.listUsers()

        this.route.navigate(['/users'])
        
      }
      


    }, error  => {

      console.log(error);
      
      let errorMessage = <any> error;

      if(errorMessage != null ){
        
        this.notification = false

      }
      

    })

  }

  realizar_busqueda(event): void {
    this.busqueda = event.valor;
    this.users.map(fila=>fila.texto_buscable = fila['name'] + ' ' +fila['email'])
  }

  orderBy(campo){
      this.fieldName = campo;
      let valores:number=document.getElementsByClassName('fa fa-sort-up').length;
      this.type = valores == 1 ?'desc':'asc';
  }

  orderIncial(){
    this.fieldName = '';
    this.type = '';
    this.click_email = false;
    this.click_name = false;
  }

}
