import { Component, OnInit } from '@angular/core';
import { NgModel } from '@angular/forms';
import { Router } from '@angular/router';
import { inventoryModel } from 'src/app/models/inventory';
import { DealerService } from 'src/app/services/dealer.service';
import { InventoryService } from 'src/app/services/inventory.service';
import { ProductsService } from 'src/app/services/products.service';

@Component({
  selector: 'app-add-inventory',
  templateUrl: './add-inventory.component.html',
})
export class AddInventoryComponent implements OnInit {

 
  notification: boolean

  inventory = new inventoryModel()
  allProducts: any [] = []
  allDealers:any [] = []
  items:any[]=[]
  items2:any[]=[]

  dealers:any[]=[]



  constructor( public productsService: ProductsService,
               private dealerService: DealerService,
               private route: Router, 
               private inventoryService: InventoryService ) { 

    this.listProducts();
    this.listDealers()
  }

  ngOnInit(): void {



  }

  listDealers(){

    this.dealerService.getDealers().subscribe( (response: any) => {

      this.dealers = response.dealersDB

      
    })

  }


  listProducts(){

    this.productsService.getProducts().subscribe( (response:any) => {

      this.allProducts = response.productsDB
      this.items = this.allProducts

    })

  }

  addInventory( inventory: inventoryModel ){

    this.inventory.inventory_id = this.inventory.dealer
    this.inventoryService.postInventory( inventory.dealer, inventory ).subscribe( (response:any) =>{

      if(!response.inventoryDB){

        this.notification = false

      } else {

        this.notification = true

        this.route.navigate(['/dealers'])

      }
      

    }, error => {

    })
    
    

  }

  reload( ){
    location.reload()
  }

}
