
<div class="col-md-12">
    <app-jumbotron></app-jumbotron>
    <div class="panel panel-lined panel-hovered  table-responsive basic-table">
        <div class="panel-heading btn-success text-white">
            Dealers
            
        </div>
        <div class="panel-body">
            <div class="clearfix">
                <buscador comentario="Realice su búsqueda por nombre ó email" (campo_buscar)="realizar_busqueda($event)"></buscador>
            </div>
            <table class="table" aria-hidden="true">
                <thead>
                    <tr>
                        <th>Foto</th>
                        <th> <span (click)="click_name=!click_name;orderBy('name')"> Nombre <i class="fa" aria-hidden="true" [ngClass]="click_name ? 'fa-sort-up' : 'fa-sort-down'"></i></span></th>
                        <th><span (click)="click_email=!click_email;orderBy('email')"> Email <i class="fa" aria-hidden="true" [ngClass]="click_email ? 'fa-sort-up' : 'fa-sort-down'"></i></span></th>
                        <th>Telefono</th>
                        <th>Fecha de creación</th>
                        <th class="col-lg-2">Acción <i class="fa-solid fa-repeat btn-repeat" aria-hidden="true" title="Orden Data Inicial" (click)="orderIncial()" ></i> </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let user of users | filtro:'texto_buscable':busqueda | sort:type:fieldName">
                        
                        <td><img *ngIf="user.img" [src]="user?.img | imagen" alt="" width="60px"></td>
                        <td> {{ user.name }}</td>
                        <td> 
                            <label class="label label-pink mr5"> {{  user.email }}  </label>
                        </td>
                        <td>
                            {{ user.phone }}
                        </td>
                        <td> {{ user.created_date  | date}}</td>
                        
                        <td>
                            <a class="btn btn-warning m-right2" [routerLink]="['/update-user', user._id]"><i class="fa fa-edit"></i></a>
                            <a class="btn btn-danger" href="#userModal-{{ user._id }}" data-toggle="modal"><i class="fa fa-trash"></i></a>
                        </td>



                         <!-- Modal -->
                         <div class="modal fade" id="userModal-{{ user._id }}" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">BORRAR DEALER</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                </div>
                                <div class="modal-body">
                                    ¿Está seguro de que desea eliminar el usuario {{ user.name }}  ?
                                    <br>
                                    <div *ngIf="notification == true" class="modal-body ">
                                        <p class="text-success  xsmedio">El Usuario fue borrado con exito!!</p>
                                        
                                    </div>
                                    
                                    <div *ngIf="notification == false">
                                        <p class="text-danger text-right xsmedio">Paso un error, no se puedes borrar ese usuario!!</p>
                                        
                                    </div>
                                </div>
                                
                                <div class="modal-footer">
                                <button type="button" class="btn btn-danger" data-dismiss="modal">Cancelar</button>
                                <button (click)="deleteUser( user )" type="button" class="btn btn-primary" >Borrar</button>
                                </div>
                            </div>
                            </div>
                        </div>


                    </tr>
                    

                </tbody>
            </table>
        </div>
    </div>


</div>


