import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TOKEN, URL_SERVICES } from '../config/config';
import { categoryModel } from '../models/category';
import { productModel } from '../models/product';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  token = TOKEN
  url = URL_SERVICES

  category = new categoryModel()

  constructor( private http: HttpClient) {
    
   }


  getOneProduct(id: string){
    const headers = new HttpHeaders({
      "token": this.token
    });

    return this.http.get(`${this.url}/product/` + id, {headers})

  }


  postProduct(id:string, product: productModel){
    
    const headers = new HttpHeaders({
      "token": this.token
    });

    return this.http.post(`${this.url}/product/` + id, product, {headers})

  }

  getProducts(){
    const headers = new HttpHeaders({
      "token": this.token
    });

    return this.http.get(`${this.url}/products/`, {headers})

  }


  deleteProduct(product: productModel){
    const headers = new HttpHeaders({
      "token": this.token
    });

    return this.http.delete(`${this.url}/product/` + product._id , {headers})

  }


  updateProduct( id: string, product: productModel){
    const headers = new HttpHeaders({
      "token": this.token
    });

    
    return this.http.put(`${this.url}/product/`+ id, product, {headers})

  }

}




