import { NgModel } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { OrderService } from 'src/app/services/order.service';
import { Order } from 'src/app/models/order';
declare var $: any;

export enum typeOrder {
  ESPECIAL = 'especial',
  NORMAL = 'normal'
}

@Component({
  selector: 'app-order-day',
  templateUrl: './order-day.component.html',
  styleUrls: ['./order-day.component.css']
})
export class OrderDayComponent implements OnInit {

  order = new Order()
  orders: any = []

  products: any[] = []
  allProducts: any[] = []

  public regularOrders = [];
  public especialOrders = [];

  public listadoProductos = [];
  public nameProduct: string;
  public type: string;
  public typeOrders = typeOrder;
  public route_number;
  public selectDateAnd;
  public allOrders:any;
  public busqueda_regular:any;
  public busqueda_especial:any;
  public valor_seleccion:string = 'regular';

  constructor(private orderService: OrderService) { }

  ngOnInit(): void {
    this.selectOrderDay()
  }

  selectOrderDay() {
    let today = new Date()
    let dd = today.getDate()
    let mm = today.getMonth() + 1;
    let yyyy = today.getFullYear();
    let mes;
    if(mm.toString().length==1){
      mes = "0"+mm;
    }else{
      mes = mm ;
    }
    let newDate = yyyy + '-' + mes + '-' + dd;
    this.order.selectDate = newDate;
    this.order.selectDateAnd = newDate;
    console.log(this.order)
    this.filtrarRangoFecha();
  }

  convertirFecha(fecha: string) {
    let date = fecha.split("-");
    if (date.length > 1) {
      let dd = date[2]
      let mm = date[1]
      let yyyy = date[0]
      let newDate = mm + '/' + dd + '/' + yyyy;
      return newDate;
    } else {
      return fecha
    }
  }

  filtrarRangoFecha() {

    let objOrder = {
      selectDate: this.convertirFecha(this.order.selectDate),
      selectDateAnd: this.convertirFecha(this.order.selectDateAnd)
    }

    this.orderService.getOrdersDay(objOrder).subscribe((response: any) => {
      this.orders = response.orders
      this.orders.map(fila=>fila.dealer_name = fila['dealer']['name']);
      this.especialOrders = response.orders.filter(ele => ele.type == typeOrder.ESPECIAL);
      this.regularOrders = response.orders.filter(ele => ele.type == typeOrder.NORMAL);
      this.especialOrders.map(fila=>fila.dealer_name = fila['dealer']['name']);
      this.regularOrders.map(fila=>fila.dealer_name = fila['dealer']['name']);
    })
  }

  obtenerProduct($event) {
    this.listadoProductos = $event['products'];
    this.nameProduct = $event['dealear'];
    this.type = $event['type'];
    this.route_number = $event['route_number'],
      this.openModal();
  }


  openModal() {
    $("#exampleModal").modal("show");
  }

  cerrarModal() {
    $("#exampleModal").modal("hide");
    this.listadoProductos = [];
  }

  exportAllCsv() {
  //  this.orderService.getEmitOrder({ orders: this.orders, rangeDate: this.order });
    this.allOrders = { orders: this.orders, rangeDate: this.order }
  }

  seleccion(valor){
    this.valor_seleccion = valor;
  }
  realizar_busqueda(event){
    if(this.valor_seleccion=='regular'){
      this.realizar_busqueda_regular(event);
      return ;
    }
    this.realizar_busqueda_especial(event);
  }

  realizar_busqueda_regular(event): void {
    this.busqueda_regular = event.valor;
    this.regularOrders.map(fila=>fila.texto_buscable = fila['dealer']['name'] + ' ' +fila['n_order'])
  }

  realizar_busqueda_especial(event): void {
    this.busqueda_especial = event.valor;
    this.especialOrders.map(fila=>fila.texto_buscable = fila['dealer']['name'] + ' ' +fila['n_order'])
  }
}
