import { Component, OnInit } from '@angular/core';
import { InventoryService } from 'src/app/services/inventory.service';

@Component({
  selector: 'app-inventories',
  templateUrl: './inventories.component.html',
})
export class InventoriesComponent implements OnInit {


  inventories: any[] = []
  newDealers:any

  constructor( private inventoriesService: InventoryService) { }

  ngOnInit(): void {

    this.listInventories()
    
  }

  listInventories(){

    this.inventoriesService.getInventories().subscribe( ( response:any ) => {

      this.inventories = response.inventoryDB

      console.log(this.inventories);
      
      
      
    })

    
  }

}
