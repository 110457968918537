import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { routeModel } from 'src/app/models/route';
import { RouteService } from 'src/app/services/route.service';

@Component({
  selector: 'app-update-route',
  templateUrl: './update-route.component.html',
})
export class UpdateRouteComponent implements OnInit {
  
  routes:any [] = []
  route = new routeModel()
  notification: boolean

  weeks:any[]
  daysofWeeks:any[]
  constructor( private routeService: RouteService,
               private router: ActivatedRoute, 
               private _route: Router) { }

  ngOnInit(): void {

    this.getOneRoute()
    this.weeks =[{ name:'Semana 1', number: 0}, { name:'Semana 2', number: 1}, { name:'Semana 3', number: 2}, { name:'Semana 4', number: 3}, { name:'Semana 5', number: 4}]
    this.daysofWeeks = [{ name:'Domingo', number: 0}, { name:'Lunes', number: 1}, { name:'Martes', number: 2}, { name:'Miercoles', number: 3}, { name:'Jueves', number: 4}, { name:'Viernes', number: 5}, { name:'Sabado', number: 6}]
    this.listWeeks()
  }



  getOneRoute() {

    this.router.paramMap.subscribe( params => {

      let id = params.get("id")
      
      this.routeService.getOneRoute(id).subscribe( ( resp:any ) =>{

        this.route = resp.routeDB
    
        
      })

    })

  }

  items:any[]=[]
  weeksSelect:any[]=[]
  newWeekArray
  listWeeks(){
    this.items =[{ name:'Semana 1', number: 0}, { name:'Semana 2', number: 1}, { name:'Semana 3', number: 2}, { name:'Semana 4', number: 3}, { name:'Semana 5', number: 4}]
  }

   async updateRoute( route: routeModel ){
    route.weeks = []
    await this.getOneRoute()
    let arrOfWeeks = this.weeksSelect.map( (week) => {
      return route.weeks.push(week.number)
    })

    this.routeService.putRoute(this.route._id, route).subscribe( (response:any) => {

      route.weeks = arrOfWeeks
      console.log(response);
      

      if(!response.routeUpdated){

        this.notification = false

      } else {

        this.notification = true

        this._route.navigate(['/routes'])

       console.log(this.notification);
      

      }
      
    }, error => {

      let errorMessage = <any> error;

      if(errorMessage != null ){
        
        this.notification = false
        console.log(this.notification);

      }
      

    })

    
  }

  reload(){
    this._route.navigate(['/routes'])
  }
}
