<div class="row ">
    <!-- dashboard header -->
    <div class="col-md-12">
        <div class="dash-head clearfix mt15 mb20">
            <div class="left">
                <h4 class="mb5 text-light">Hola, {{ this.name }}</h4>
            </div>
            <div class="right">
                <h4 class="mb5 text-light"></h4>

                <button data-toggle="modal" data-target="#userModal"
                class="btn btn-purple btn-icon-inline waves-effect m-right"><i class="fa fa-plus"></i>Usuario</button> 

                <button data-toggle="modal" data-target="#categoryModal"
                class="btn btn-primary btn-icon-inline waves-effect m-right"><i class="fa fa-plus"></i>Categoria</button> 
                
                <button data-toggle="modal" data-target="#productModal" type="button" 
                class="btn btn-info btn-icon-inline waves-effect m-right"><i class="fa fa-plus"></i>Producto</button>
                
                <button type="button" data-toggle="modal" data-target="#routeModal"
                class="btn btn-warning btn-icon-inline waves-effect m-right"><i class="fa fa-plus"></i>Ruta</button>
                
                <button type="button" data-toggle="modal" data-target="#dealerModal"
                class="btn btn-success btn-icon-inline waves-effect m-right"><i class="fa fa-plus"></i>Dealer</button>

                <button type="button" data-toggle="modal" data-target="#inventoryModal"
                class="btn btn-danger btn-icon-inline waves-effect m-right"><i class="fa fa-plus"></i>Inventario</button>
            
              </div>

            
            <div class="right mt10">
            </div>
        </div>
    </div>
</div> <!-- #end row -->

  
  <!-- MODEL CATEGORY -->
  <div class="modal fade" id="categoryModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Agregar Categoria</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <br>
          <app-category></app-category>
          <br>
        </div>
        <br>

      </div>
    </div>
  </div>

<!-- MODEL PRODUCT -->
<div class="modal fade" id="productModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Agregar Producto</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <br>
        <app-add-product></app-add-product>
        <br>
      </div>
      <br>

    </div>
  </div>
</div>

<!-- MODEL ROUTE -->
<div class="modal fade" id="routeModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Agregar Ruta</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <br>
        <app-add-route></app-add-route>
        <br>
      </div>
      <br>

    </div>
  </div>
</div>

<!-- MODEL DEALER -->
<div class="modal fade" id="dealerModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Agregar Ruta</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <br>
        <app-add-dealer></app-add-dealer>
        <br>
      </div>
      <br>

    </div>
  </div>
</div>

<!-- MODEL INVENTORY -->
<div class="modal fade" id="inventoryModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Agregar Inventario</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <br>
        <app-add-inventory></app-add-inventory>
        <br>
      </div>
      <br>

    </div>
  </div>
</div>

<!-- MODEL ADD USER -->
<div class="modal fade" id="userModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Agregar Usuario</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <br>
        <app-add-user></app-add-user>
        <br>
      </div>
      <br>

    </div>
  </div>
</div>