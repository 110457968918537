import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

// Rutas
import { APP_ROUTES } from './app.routes';


import { FormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { HeaderComponent } from './shared/header/header.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { PageComponent } from './pages/page/page.component';

import { DashboardComponent } from './shared/dashboard/dashboard.component';

import { FilterPipe } from './pipes/filter.pipe';
import { ImagenPipe } from './pipes/imagen.pipe';
import { PerfilComponent } from './pages/perfil/perfil.component';
import { JumbotronComponent } from './shared/jumbotron/jumbotron.component';

import { ReactiveFormsModule } from '@angular/forms';
import { InventoriesComponent } from './components/inventories/inventories.component';
import { DealersComponent } from './components/dealers/dealers.component';
import { DealerComponent } from './components/dealers/dealer/dealer.component';
import { CategoriesComponent } from './components/categories/categories.component';
import { CategoryDetailComponent } from './components/categories/category-detail/category-detail.component';
import { UpdateCategoryComponent } from './components/categories/update-category/update-category.component';
import { AddProductComponent } from './components/products/add-product/add-product.component';
import { ProductsComponent } from './components/products/products.component';
import { ProductUpdateComponent } from './components/products/product-update/product-update.component';
import { ProductDetailComponent } from './components/products/product-detail/product-detail.component';
import { CategoryComponent } from './components/categories/category/category.component';
import { RoutesComponent } from './components/routes/routes.component';
import { AddRouteComponent } from './components/routes/add-route/add-route.component';
import { UpdateRouteComponent } from './components/routes/update-route/update-route.component';
import { RouteDetailComponent } from './components/routes/route-detail/route-detail.component';
import { AddDealerComponent } from './components/dealers/add-dealer/add-dealer.component';

import { NgSelectModule } from '@ng-select/ng-select';
import { UpdateDealerComponent } from './components/dealers/update-dealer/update-dealer.component';
import { AddInventoryComponent } from './components/inventories/add-inventory/add-inventory.component';
import { UpdateInventoryComponent } from './components/inventories/update-inventory/update-inventory.component';
import { InventoryDetailComponent } from './components/inventories/inventory-detail/inventory-detail.component';
import { UsersComponent } from './components/users/users.component';
import { AddUserComponent } from './components/users/add-user/add-user.component';
import { UpdateUserComponent } from './components/users/update-user/update-user.component';
import { UserDetailComponent } from './components/users/user-detail/user-detail.component';
import { OrdersComponent } from './components/orders/orders.component';
import { OrderDetailComponent } from './components/orders/order-detail/order-detail.component';
import { OrderDayComponent } from './components/orders/order-day/order-day.component';
import { OrderDayTableComponent } from './components/orders/order-day/order-day-table/order-day-table.component';
import { BuscadorModule } from './shared/buscador/buscador.module';
import { FiltroPipe } from './pipes/filtro.pipe';
import { SortTablePipe } from './pipes/sort-table.pipe';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HeaderComponent,
    SidebarComponent,
    PageComponent,

    DashboardComponent,
    FilterPipe,
    ImagenPipe,
    PerfilComponent,
    JumbotronComponent,
    InventoriesComponent,
    DealersComponent,
    DealerComponent,
    CategoryComponent,
    CategoryDetailComponent,
    CategoriesComponent,
    UpdateCategoryComponent,
    AddProductComponent,
    ProductsComponent,
    ProductUpdateComponent,
    ProductDetailComponent,
    RoutesComponent,
    AddRouteComponent,
    UpdateRouteComponent,
    RouteDetailComponent,
    AddDealerComponent,
    UpdateDealerComponent,
    AddInventoryComponent,
    UpdateInventoryComponent,
    InventoryDetailComponent,
    UsersComponent,
    AddUserComponent,
    UpdateUserComponent,
    UserDetailComponent,
    OrdersComponent,
    OrderDetailComponent,
    OrderDayComponent,
    OrderDayTableComponent,
    FiltroPipe,
    SortTablePipe

    

  ],
  imports: [
    BrowserModule,
    APP_ROUTES,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    BuscadorModule



  ],
  providers: [

  ],
  bootstrap: [AppComponent]
})
export class AppModule { 
  NgxStripeModule
}
