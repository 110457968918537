    <form  (ngSubmit)="updatePerfil( f )" #f="ngForm">
        <div class="col-md-5 pt-5 mt-50">
        <div class="form-group">
          <label >Nombre</label>
          <input  [(ngModel)]="user.name" name="name"
          type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp">
        </div>
        <div class="form-group">
          <label>Correo electrónico</label>
          <input [(ngModel)]="user.email" name="email"
          type="email" class="form-control" id="exampleInputPassword1">
        </div>
        
        <div class="form-group">
            <label>Teléfono</label>
            <input  [(ngModel)]="user.phone" name="phone"
            type="text" class="form-control" id="exampleInputPassword1">
          </div>
          <div class="form-group">
            <label>Nueva contraseña</label>
            <input  [(ngModel)]="user.password" name="password" 
            type="password" class="form-control" id="exampleInputPassword1">
          </div>
          <div class="form-group">
            <label>Confirmar contraseña</label>
            <input  [(ngModel)]="user.confirm_password" name="confirm_password" 
            type="password" class="form-control" id="exampleInputPassword1">
          </div>
          


        <button type="submit" class="btn btn-primary">Actualizar</button>

        </div>

        <div class="col-md-2">

        </div>
        <div class="col-md-5">
            <img [src]="this.img | imagen" alt="admin-pic" class="m-top img-perfil">
            <div class="form-group m-top">
              <input type="file" class="btn btn-primary bt" (change)="onFileSelected($event)">
               <a (click)="upload()" class="btn btn-success bt-archive">Enviar</a>

              <div *ngIf="alert === true">
                <div class="alert alert-success">
                  <button type="button" class="close" data-dismiss="alert">
                    <span aria-hidden="true">×</span>
                  </button>
                  <div>Informacion actualizada!!!</div>
                </div>
        
              </div>

              <div *ngIf="alert === false">
                <div class="alert alert-danger">
                  <button type="button" class="close" data-dismiss="alert">
                    <span aria-hidden="true">×</span>
                  </button>
                  <div>Verifique la información en el campo de "contraseña"!</div>
                </div>
        
              </div>
              
            </div>
            

    </div>
    
      </form>


      
