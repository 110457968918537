<form (ngSubmit)="updateRoute( f.value )" #f="ngForm"

  role="form" class="form-horizontal" >
    <!-- normal control -->
    <div class="form-group">
        <label class="col-md-3 control-label">Numero De Ruta</label>
        <div class="col-md-9">
            <input [(ngModel)]="route.number" name="number" #brand="ngModel" required 
            type="number" class="form-control">
        </div>
    </div>
   <!-- <div class="form-group">
        <label class="col-md-3 control-label">Fechas</label>
        <div class="col-md-9">
            <input [(ngModel)]="route.date" name="date" #date="ngModel" disabled
            type="text" class="form-control">
        </div>
    </div>/*-->

    <div class="form-group">
        <p class="text-primary text-right xsmall"> La primera semana o dia, es igual a 0, 1 es = a la segunda semana. </p>

        <label class="col-md-3 control-label">Semana</label>
        <div class="col-md-9">

            <ng-select  [items]="items" [(ngModel)]="weeksSelect" name="weeks" 


            bindLabel="number"


            placeholder="route.weeks"


            appendTo="body"


            multiple="true"
>


 </ng-select>
        </div>
    </div>


      <!-- mensajes de erros Week 
      <div *ngIf="weeks.invalid && (weeks.dirty || weeks.touched)"
      class="alert alert-danger">
  
    <div *ngIf="weeks.errors.required">
        <p class="text-danger text-right xsmall"> Please, selecionar la semana que el cliente recibira la notificación</p>
        
    </div>
    </div>-->

    <div class="form-group">
        <label class="col-md-3 control-label">Dia</label>
        <div class="col-md-9">
            <select class="form-control" [(ngModel)]="route.day" name="day" #day="ngModel" required>
                <option *ngFor="let daysofWeek of daysofWeeks; let i = index"
                [value]="daysofWeek.number">{{daysofWeek.name}}</option>

            </select>
        </div>
    </div>
      <!-- mensajes de erros Week -->
      <div *ngIf="day.invalid && (day.dirty || day.touched)"
      class="alert alert-danger">
  
    <div *ngIf="day.errors.required">
        <p class="text-danger text-right xsmall"> Please, selecionar la semana que el cliente recibira la notificación</p>
        
    </div>
    </div>

   
    
        <!-- mensajes de erros -->
       <!-- <div *ngIf="date.invalid && (date.dirty || date.touched)"
        class="alert alert-danger">
        
    <div *ngIf="date.errors.required">
        <p class="text-danger text-right xsmall">El description SKU es requerido. Please, selecionar nombre del cliente.</p>
            
    </div>
    </div>-->

    <div *ngIf="notification == true">
        <p class="text-success text-right xsmedio">Salvo con exito!!</p>
            
    </div>

    <div *ngIf="notification == false">
        <p class="text-danger text-right xsmedio">Paso un error, verifica se estas llenando el formulario correctamento!!</p>
            
    </div>


        

   <!-- with hint -->


   <!--Opcao subir imagem aqui-->


    <!--<div *ngIf="notification">
        <p class="text-success text-right xsmall">Salvo con exito!!</p>
        
    </div>-->

    <button type="button" class="btn btn-danger  right " (click)="reload()">Cancelar</button>
    
    <button type="submit" class="btn btn-success m-right2 m-left right" [disabled]="f.invalid">Guardar</button>

</form>