

<div class="col-md-12">
    <app-jumbotron></app-jumbotron>
    <div class="panel panel-lined panel-hovered  table-responsive basic-table">
        <div class="panel-heading btn-info text-white">
            Productos
            
        </div>
        <div class="panel-body">
            <table class="table">
                <thead>
                    <tr>
                        <th> Marca </th>
                        <th> Rated </th>
                        <th> Group </th>
                        <th> SKU </th>
                        <th> Descripción </th>
                        <th> QTD </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let product of products">
                        
                        <td> {{ product.brand }}</td>
                        <td> {{ product.rated }} </td>
                        <td> {{ product.group }} </td>
                        <td> {{ product.part_number }} </td>
                        <td> {{ product.description }}</td>
                        <td><span class="badge  badge-sm badge-info"> {{ product.quantity }} </span> </td>



                    </tr>
                    

                </tbody>
            </table>
        </div>
    </div>


</div>


 
