<form (ngSubmit)="addInventory( f.value )" #f="ngForm"

  role="form" class="form-horizontal" >

    <div class="form-group">
        <label class="col-md-3 control-label">Dealer</label>
        <div class="col-md-9">
            <select class="form-control" [(ngModel)]="inventory.dealer" name="dealer" required>
                <option *ngFor="let dealer of dealers" 
                 [value]="dealer._id">{{dealer.name}}</option>

            </select>
        </div>
    </div>


    <div class="form-group">
        <label class="col-md-3 control-label">Productos</label>
        <div class="col-md-9">

            <ng-select  [items]="items" [(ngModel)]="inventory.products" name="products" 


               bindLabel="part_number"


               placeholder="Selecionar products"


               appendTo="body"


               multiple="true"
>


    </ng-select>

        </div>
    </div>


    


    


        <div *ngIf="notification == true">
            <p class="text-success text-right xsmedio">Salvo con exito!!</p>
            
        </div>

        <div *ngIf="notification == false">
            <p class="text-danger text-right xsmedio">Paso un error, verifica se estas llenando el formulario correctamento o el Inventory ID existe!!</p>
            
        </div>


        

   <!-- with hint -->


   <!--Opcao subir imagem aqui-->


    <!--<div *ngIf="notification">
        <p class="text-success text-right xsmall">Salvo con exito!!</p>
        
    </div>-->

    <button type="button" class="btn btn-danger  right " (click)="reload()">Cancelar</button>
    
    <button type="submit" class="btn btn-success m-right2 m-left right" [disabled]="f.invalid">Guardar</button>

</form>