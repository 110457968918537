import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { TOKEN, URL_SERVICES, USER_ID } from 'src/app/config/config';
import { UserModel } from 'src/app/models/user';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
})
export class AddUserComponent implements OnInit {

  user = new UserModel();
  
  notification: boolean


  constructor(  private router: Router,
                private userService: UserService, 
                private http:HttpClient,
                private route: Router) {}



ngOnInit(): void {}

createUser(f: NgForm){

if(f.value.password == f.value.confirm_password){

this.userService.postUser(this.user).subscribe( (response:any) =>{

  console.log(response)
  
  if(!response.userMod){

    this.notification = false

  } else {

    this.notification = true

    this.route.navigate(['/dealers'])

  }

}, error => {

  let errorMessage = <any> error;

  if(errorMessage != null ){
    
    this.notification = false

  }
  

})

}




}



}