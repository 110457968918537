

<div class="col-md-12">
    <app-jumbotron></app-jumbotron>
    <div class="panel panel-lined panel-hovered  table-responsive basic-table">
        <div class="panel-heading btn-info text-white">
            Productos
            
        </div>
        <div class="panel-body">
            <div class="clearfix">
                <buscador comentario="Realice su búsqueda por la marca, group ó SKU" (campo_buscar)="realizar_busqueda($event)"></buscador>
            </div>
            <table class="table" aria-hidden="true">
                <thead>
                    <tr>
                        <th> <span (click)="click_marca=!click_marca;orderBy('brand')"> Marca <i class="fa" aria-hidden="true" [ngClass]="click_marca ? 'fa-sort-up' : 'fa-sort-down'"></i></span> </th>
                        <th> Rated </th>
                        <th> <span (click)="click_group=!click_group;orderBy('group')"> Group <i class="fa" aria-hidden="true" [ngClass]="click_group ? 'fa-sort-up' : 'fa-sort-down'"></i></span> </th>
                        <th> <span (click)="click_sku=!click_sku;orderBy('part_number')"> SKU <i class="fa" aria-hidden="true" [ngClass]="click_sku ? 'fa-sort-up' : 'fa-sort-down'"></i></span> </th>
                        <th> CCA </th>
                        <th> CA </th>
                        <th> Descripción </th>
                        <!-- <th> Categoria </th> -->
                        <th> QTD </th>
                        <th class="col-lg-2">Editar  / Borrar <i class="fa-solid fa-repeat btn-repeat" aria-hidden="true" title="Orden Data Inicial" (click)="orderIncial()" ></i></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let product of products | filtro:'texto_buscable':busqueda | sort:type:fieldName">
                        
                        <td> {{ product.brand }}</td>
                        <td> {{ product.rated }} </td>
                        <td> {{ product.group }} </td>
                        <td> {{ product.part_number }} </td>
                        <td> {{ product.cca }}</td>
                        <td> {{ product.ca }}</td>
                        <td> {{ product.description }}</td>

                        <!-- <td> <span class="badge  badge-sm badge-info">{{ product.category.name }}</span></td> -->
                        <td> <span class="badge  badge-sm badge-success">{{ product.quantity }}</span></td>


                        <td>
                            <a class="btn btn-warning m-right2" [routerLink]="['/product-update/', product._id]"><i class="fa fa-edit"></i></a>

                            <a class="btn btn-danger" href="#productModal-{{ product._id }}" data-toggle="modal"><i class="fa fa-trash"></i></a>
                        </td>



                         <!-- Modal -->
                         <div class="modal fade" id="productModal-{{ product._id }}" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">BORRAR PRODUCTO</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                </div>
                                <div class="modal-body">
                                    ¿Está seguro de que desea eliminar el producto {{ product.brand }} ?
                                    <br>
                                    <div *ngIf="notification == true" class="modal-body ">
                                        <p class="text-success  xsmedio">El producto fue borrado con exito!!</p>
                                        
                                    </div>
                                    
                                    <div *ngIf="notification == false">
                                        <p class="text-danger text-right xsmedio">Paso un error, no se puedes borrar ese producto!!</p>
                                        
                                    </div>
                                </div>
                                
                                <div class="modal-footer">
                                <button type="button" class="btn btn-danger" data-dismiss="modal">Cancelar</button>
                                <button (click)="deleteOneProduct( product )" type="button" class="btn btn-primary" >Borrar</button>
                                </div>
                            </div>
                            </div>
                        </div>


                    </tr>
                    

                </tbody>
            </table>
        </div>
    </div>


</div>


 
