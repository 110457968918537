<div class="container">

<form  (ngSubmit)="createUser( f )" #f="ngForm">
    <div class="col-md-5 pt-5 mt-50">
    <div class="form-group">
      <label >Nombre</label>
      <input  [(ngModel)]="user.name" name="name"
      type="text" class="form-control" >
    </div>

    <div class="form-group">
      <label>Correo electrónico</label>
      <input [(ngModel)]="user.email" name="email" #email="ngModel"
      type="email" class="form-control" required>
    </div>

    <div *ngIf="email.invalid && (email.dirty || email.touched)"
    class="alert alert-danger">

    <div *ngIf="email.errors.required">
        <p class="text-danger text-right xsmall">El email es requerido</p>
        
    </div>
    </div>

    <div class="form-group">
        <label>Teléfono</label>
        <input  [(ngModel)]="user.phone" name="phone"
        type="text" class="form-control" >
      </div>
      <div class="form-group">
        <label>Contraseña</label>
        <input  [(ngModel)]="user.password" name="password" #password="ngModel"
        type="password" class="form-control" required >
      </div>

      <div *ngIf="password.invalid && (password.dirty || password.touched)"
      class="alert alert-danger">
  
      <div *ngIf="password.errors.required">
          <p class="text-danger text-right xsmall">El password es requerido</p>
          
      </div>
      </div>


      <div class="form-group">
        <label>Confirmar contraseña</label>
        <input  [(ngModel)]="user.confirm_password" name="confirm_password" #confirm_password="ngModel"
        type="password" class="form-control" required >
      </div>
      
      <div *ngIf="confirm_password.invalid && (confirm_password.dirty || confirm_password.touched)"
      class="alert alert-danger">
  
      <div *ngIf="confirm_password.errors.required">
          <p class="text-danger text-right xsmall">El confirmar password es requerido</p>
          
      </div>
      </div>

      <div *ngIf="notification == true">
        <p class="text-success text-right xsmedio">Salvo con exito!!</p>
        
    </div>

    <div *ngIf="notification == false">
        <p class="text-danger text-right xsmedio">Paso un error, verifica se estas llenando el formulario correctamento!!</p>
        
    </div>

    <button type="submit" class="btn btn-purple">Guardar</button>

    </div>

  </form>


  

</div>