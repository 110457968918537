import { Component, EventEmitter, Input, OnInit, Output, SimpleChange } from '@angular/core';
import { ExportToCsv } from 'export-to-csv';
import { typeOrder } from '../order-day.component';

@Component({
  selector: 'app-order-day-table',
  templateUrl: './order-day-table.component.html',
  styleUrls: ['./order-day-table.component.css']
})
export class OrderDayTableComponent implements OnInit {

  @Input() ordersList: any[] = [];
  @Input() allOrders: any;
  @Input() busqueda: any;

  @Output() productList: EventEmitter<any> = new EventEmitter();
  public tipoOrden = typeOrder;
  options = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    showTitle: true,
    title: 'My Order',
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: false,
    headers: ['Id Delear', 'Dealer', 'Router Number', 'Type', 'Date', 'N° Order', 'Brand', 'Cca', 'Group', 'Description', 'Part Number', 'Quantity']
  };

  listadoAllOrders = [];
  fechas: any;
  data_exportar = [];

  fieldName:any;
  click_name:boolean = false;
  click_order:boolean = false;
  type:string = '';

  constructor() { }

  ngOnChanges(changes: SimpleChange) {
    if (this.allOrders && this.allOrders['orders'].length > 0 && this.allOrders['rangeDate'] && this.allOrders['rangeDate']['selectDate'] && this.allOrders['rangeDate']['selectDateAnd']) {
      this.listadoAllOrders = this.allOrders['orders'];
      this.fechas = this.allOrders['rangeDate'];
      this.exportAllCsv()
    }
  }


  ngOnInit(): void {
  }

  obtenerProducto(orderList: any[]) {
    let orderProduct = {
      products: orderList['products'] ? orderList['products'] : '',
      dealear: orderList['dealer'] ? orderList['dealer']['name'] : '',
      delear_id: orderList['dealer'] ? orderList['dealer']['dealear_id'] : '',
      route_number: orderList['dealer'] && orderList['dealer']['route'] ? orderList['dealer']['route']['number'] : '',
      type: orderList['type'] ? orderList['type'] : ''
    }
    this.productList.emit(orderProduct)
  }

  exportLinealCsv(orderList: any[]) {
    this.data_exportar = [];
    this.options.title = orderList['dealer'] && orderList['dealer']['name'] ? (orderList['dealer']['name'] as string).toUpperCase() + ' - ' + orderList['type'] : '';
    const csvExporter = new ExportToCsv(this.options);
    this.recorrerData(orderList);
    csvExporter.generateCsv(this.data_exportar);
  }

  exportAllCsv() {
    this.data_exportar = [];
    let csvExporter;
    this.options.title = "All Orders from " + this.fechas.selectDate + " from " + this.fechas.selectDateAnd;
    this.listadoAllOrders.forEach(orderList => {
      csvExporter = new ExportToCsv(this.options);
      this.recorrerData(orderList);
    })
    csvExporter.generateCsv(this.data_exportar);
  }

  recorrerData(orderList: any[]) {
    if (orderList['products'].length > 0) {
      orderList['products'].forEach(element => {
        this.llenarDataExportar(element, orderList);
      });
    }
  }

  llenarDataExportar(element: any[], orderList: any[]) {
    element.forEach(prod => {
      this.data_exportar.push({
        delear_id: orderList['dealer'] ? orderList['dealer']['dealer_id'] : '',
        dealear: orderList['dealer'] ? orderList['dealer']['name'] : '',
        route_number: orderList['dealer'] && orderList['dealer']['route'] ? orderList['dealer']['route']['number'] : '',
        type: orderList['type'] ? orderList['type'] : '',
        created_date: orderList['created_date'] ? new Date(orderList['created_date']).toDateString() : '',
        n_order: orderList['n_order'] ? orderList['n_order'] : '',
        brand: prod['brand'] ? prod['brand'] : '',
        cca: prod['cca'] ? prod['cca'] : '',
        group: prod['group'] ? prod['group'] : '',
        description: prod['description'] ? prod['description'] : '',
        part_number: prod['part_number'] ? prod['part_number'] : '',
        quantity: prod['quantity'] ? prod['quantity'] : ''
      });
    });
  }

orderBy(campo){
    this.fieldName = campo;
    let valores:number=document.getElementsByClassName('fa fa-sort-up').length;
    this.type = valores == 1 ?'desc':'asc';
}

orderIncial(){
  this.fieldName = '';
  this.type = '';
  this.click_order = false;
  this.click_name = false;
}




}
