<div class="col-md-12">
    <app-jumbotron></app-jumbotron>
    <div class="panel panel-lined panel-hovered  table-responsive basic-table">
        <div class="panel-heading btn-warning text-black">
            Rutas
            
        </div>
        <div class="panel-body">
            <div class="clearfix">
                <buscador comentario="Realice su búsqueda por el número de ruta" (campo_buscar)="realizar_busqueda($event)"></buscador>
            </div>
            <table class="table" aria-hidden="true">
                <thead>
                    <tr>
                        <th class="col-lg-1"><button type="button"  class="btn btn-default btn-sm fa  fa-check waves-effect"></button></th>
                        <th><span (click)="click_numero_ruta=!click_numero_ruta;orderBy('number')"> Número de ruta <i class="fa" aria-hidden="true" [ngClass]="click_numero_ruta ? 'fa-sort-up' : 'fa-sort-down'"></i></span></th>
                        <th><span (click)="click_cantidad_delear=!click_cantidad_delear;orderBy('cantidad')"> QTD Dealers <i class="fa" aria-hidden="true" [ngClass]="click_cantidad_delear ? 'fa-sort-up' : 'fa-sort-down'"></i></span></th>
                        <th>Dates programacion</th>
                        <th>Acción <i class="fa-solid fa-repeat btn-repeat" aria-hidden="true" title="Orden Data Inicial" (click)="orderIncial()"></i></th>

                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let route of routes | filtro:'texto_buscable':busqueda | sort:type:fieldName; let i = index">
                        <td>
                            <div class="ui-checkbox ui-checkbox-primary ml5">
                                <label><input type="checkbox"><span></span>
                                </label>
                            </div>
                        </td>
                        <td> <label class="label label-pink mr5"> {{ route.number }}</label></td>
                        <td>{{ route.cantidad }}</td>
                        <td>
                            <label class=""> Week: {{ route.weeks  }} | Day {{route.day }} </label>
                        </td>
                        <td>
                            <a class="btn btn-primary m-right2" [routerLink]="['/route', route._id]"> <i class="fa fa-eye"></i></a>
                            <a class="btn btn-warning m-right2" [routerLink]="['/update-route', route._id]"><i class="fa fa-edit"></i></a>
                            <a class="btn btn-danger" href="#routeModal-{{ route._id }}" data-toggle="modal"><i class="fa fa-trash"></i></a>
                        </td>



                        <!-- Modal -->
                        <div class="modal fade" id="routeModal-{{ route._id }}" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">BORRAR PRODUCTO</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                </div>
                                <div class="modal-body">
                                    ¿Está seguro de que desea eliminar la ruta {{ route.number }} ?
                                    <br>
                                    <div *ngIf="notification == true" class="modal-body ">
                                        <p class="text-success  xsmedio">El producto fue borrado con exito!!</p>
                                        
                                    </div>
                                    
                                    <div *ngIf="notification == false">
                                        <p class="text-danger text-right xsmedio">Paso un error, no se puedes borrar ese producto!!</p>
                                        
                                    </div>
                                </div>
                                
                                <div class="modal-footer">
                                <button type="button" class="btn btn-danger" data-dismiss="modal">Cancelar</button>
                                <button (click)="deleteRoute( route )" type="button" class="btn btn-primary" >Borrar</button>
                                </div>
                            </div>
                            </div>
                        </div>


                    </tr>
                    

                </tbody>
            </table>
        </div>
    </div>
    <div class=" right">
        <button class="btn btn-success m-right"><i class="fa fa-plus-circle"></i> Agregar</button>
        <button class="btn btn-danger" [routerLink]="['/dashboard']">Cancelar</button>
    </div>

</div>

