import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { dealerModel } from 'src/app/models/dealer';
import { inventoryModel } from 'src/app/models/inventory';
import { DealerService } from 'src/app/services/dealer.service';
import { InventoryService } from 'src/app/services/inventory.service';
import { ProductsService } from 'src/app/services/products.service';

@Component({
  selector: 'app-update-inventory',
  templateUrl: './update-inventory.component.html',
  styleUrls: ['./update-inventory.component.css']
})
export class UpdateInventoryComponent implements OnInit {

 
  notification: boolean

  dealer = new dealerModel()
  inventory = new inventoryModel()

  allProducts: any [] = []
  allDealers:any [] = []
  items:any[]=[]
  items2:any[]=[]

  dealers:any[]=[]



  constructor( public productsService: ProductsService,
               private dealerService: DealerService,
               private route: Router, 
               private inventoryService: InventoryService,
               private router: ActivatedRoute ) { 

    this.listProducts();
    this.listDealers()
    this.getIdInventory()
  }

  ngOnInit(): void {



  }

  listDealers(){

    this.dealerService.getDealers().subscribe( (response: any) => {

      this.dealers = response.dealersDB

      
    })

  }


  listProducts(){

    
    this.productsService.getProducts().subscribe( (response:any) => {

      this.allProducts = response.productsDB
      this.items = this.allProducts
      
      
    })

  }

  getIdInventory() {

    this.router.paramMap.subscribe( params => {

      let id = params.get("id")
      
      this.inventoryService.getOneInventory(id).subscribe( ( resp:any ) =>{

        this.inventory = resp.inventoryDB

        
               
      })

    })

  }

  updateInventory( inventory: inventoryModel ){

    // This inventory come in with of the method params

    
    
    this.inventoryService.putInventory( this.inventory._id, inventory ).subscribe( (response:any) =>{

      
      if(!response.inventoryUpdate){

        this.notification = false

      } else {

        this.notification = true

        this.route.navigate([`/dealers`])

      }
      

    }, error => {

    })
    
    

  }

  reload( ){

  }

}
