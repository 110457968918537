export class dealerModel {

    _id: string
    name: string
    address: string
    city: string
    phone: string
    dealer_id: number
    img: string
    inventory: string
    route: string
    categories: any
    user: string
    category: any



}