<app-jumbotron></app-jumbotron>
<div class="row ">
    <div class="col-md-12">
        <div class="dash-head">
            <div class="row">
                <div class="col-md-4">
                    <div class="form-group">
                        <small>Fecha Inicial</small>
                        <input type="date" class="form-control" [(ngModel)]="order.selectDate">
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <small >Fecha Fin</small>
                        <input type="date" class="form-control" [(ngModel)]="order.selectDateAnd">
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="form-group">
                        <button class="btn-search" [disabled]="!(order.selectDate && order.selectDateAnd)" (click)="filtrarRangoFecha()"> <i class="fa fa-search" aria-hidden="true"></i> </button>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="form-group">
                        <button class="btn btn-primary m-right2 btn-export-all"  title="Exportar todo por Rango de Fecha" [disabled]="orders.length == 0" (click)="exportAllCsv()"><i class="fas fa-file-excel" aria-hidden="true"></i> Export All</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<br>
<div class="clearfix tabs-fill">
    <ul class="nav nav-tabs">
        <li class="active"><a href="#tab-flip-one-1" data-toggle="tab" (click)="seleccion('regular')">Regular</a></li>
        <li><a href="#tab-flip-two-1" data-toggle="tab" (click)="seleccion('especial')">Especial</a></li>
    </ul>
    <div class="tab-content">
        <div class="tab-pane active" id="tab-flip-one-1">
            <div class="clearfix">
                <buscador comentario="Realice su búsqueda por dealer ó N° Order" (campo_buscar)="realizar_busqueda($event)"></buscador>
            </div>
            <div class="clearfix">
                <span class="badge badge-sm badge-success">ordenes regulares</span>
                <div class="panel-body">
                    <app-order-day-table [allOrders]="allOrders" [ordersList]="regularOrders" [busqueda]="busqueda_regular" (productList)="obtenerProduct($event)">
                    </app-order-day-table>
                </div>
            </div>
        </div>
        <div class="tab-pane" id="tab-flip-two-1">
            <div class="clearfix">
                <buscador comentario="Realice su búsqueda por dealer ó N° Order" (campo_buscar)="realizar_busqueda($event)"></buscador>
            </div>
            <div class="clearfix">
                <span class="badge badge-sm badge-info">ordenes especiales</span>
                <app-order-day-table [ordersList]="especialOrders" [busqueda]="busqueda_especial" (productList)="obtenerProduct($event)">
                </app-order-day-table>
            </div>
        </div>

    </div>
</div>


<!--modal detalles order-->

<div class="modal fade" id="exampleModal" tabindex="-1" data-backdrop="static" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title text-center" id="exampleModalLabel"> {{nameProduct | uppercase}} - <span
                        class="badge  badge-sm" [ngClass]="type == typeOrders.NORMAL ? 'badge-success' :'badge-info' "
                        class="badge  badge-sm badge-info">{{type}}</span> <strong> - {{route_number}} </strong> </h5>
                <button (click)="cerrarModal()" type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="max-scroll">
                    <div *ngFor="let listProd of listadoProductos">
                        <div *ngFor="let prod of listProd">
                            <fieldset>
                                <legend>{{prod['brand']}}</legend>
                                <div class="row">
                                    <div class="col-md-8">
                                        <small><strong>CCA: </strong> <span class="text-light">{{prod['cca']}}</span>
                                        </small>
                                        <small><strong>Description: </strong> <span
                                                class="text-light">{{prod['description']}}</span> </small>
                                        <small><strong>Group: </strong> <span
                                                class="text-light">{{prod['group']}}</span> </small>
                                        <small><strong>Quantity: </strong> <span
                                                class="text-light">{{prod['quantity']}}</span> </small>
                                    </div>

                                </div>
                            </fieldset>

                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                    (click)="cerrarModal()">Close</button>
            </div>
        </div>
    </div>
</div>