import { Component, OnInit } from '@angular/core';
import { NgModel } from '@angular/forms';
import { Router } from '@angular/router';
import { categoryModel } from 'src/app/models/category';
import { productModel } from 'src/app/models/product';
import { CategoryService } from 'src/app/services/category.service';
import { ProductsService } from 'src/app/services/products.service';

@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.component.html',
})
export class AddProductComponent implements OnInit {


  product = new productModel()

  categories:any = []
  category = new categoryModel()

  notification;

  constructor(private categoryService: CategoryService,
              private productsService: ProductsService,
              private route: Router ) { }

  ngOnInit(): void {

    this.getCategories()

  }


  getCategories(){

    this.categoryService.getCategories().subscribe( (response:any) =>{

      this.categories = response.CategoriesDB

      
      
    })

  }


  addProduct( f: NgModel ){


   // console.log('product', product);
    
    
    this.category._id = this.product.category
    
      
    this.productsService.postProduct(this.category._id, this.product).subscribe( (response:any) =>{

      if(!response.productDB){

        this.notification = false

      } else {

        this.notification = true

        this.route.navigate(['/products'])

      

      }


      
    }, error => {

      let errorMessage = <any> error;

      if(errorMessage != null ){
        
        this.notification = false

        this.reload();

      }
      

    })
    

  }


  reload(){

    this.route.navigate(['/dashboard'])
    location.reload()
  }

  
}



