import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})


export class PushService {


  constructor() { }
  
}