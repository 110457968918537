import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TOKEN, URL_SERVICES, USER_ID } from '../config/config';
import { UserModel } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  urlMysql_ = 'https://sendtopuertorico.com/registros-de-usuarios/api-buzones/getData.php'
  url = URL_SERVICES
  token = TOKEN;
  user_id = USER_ID;


  constructor( private http:HttpClient) { }
  autenticationGuard(){
    return (this.token ) ? true : false;
  }



  getUsers(){
    const headers = new HttpHeaders({
      "token": this.token
    });

    return this.http.get(`${this.url}/users/`, {headers})

  }


  getUser(){
      const headers = new HttpHeaders({
        "token": this.token
      });

      return this.http.get(`${this.url}/user/${this.user_id}`, {headers})

    }

    getUserClient( id: string){
      const headers = new HttpHeaders({
        "token": this.token
      });

      return this.http.get(`${this.url}/user/`+ id, {headers})

    }

    postUser( user: UserModel){
      const headers = new HttpHeaders({
        "token": this.token
      });

      return this.http.post(`${this.url}/user/`, user, {headers})

    }

    updateUser(user: UserModel){
      const headers = new HttpHeaders({
        "token": this.token
      });
      
      return this.http.put(`${this.url}/user/${this.user_id}`, user, {headers})
  
    }

    putUserClient(id:string, user: UserModel){
      const headers = new HttpHeaders({
        "token": this.token
      });
      
      return this.http.put(`${this.url}/user/`+ id, user, {headers})
  
    }

  
    deleteUser( user: UserModel ){
      const headers = new HttpHeaders({
        "token": this.token
      });
  
      
      return this.http.delete(`${this.url}/user/`+ user._id, {headers})
  
    }
  
  
  }