import { Component, OnInit } from '@angular/core';
import { CategoryService } from 'src/app/services/category.service';
import { categoryModel } from 'src/app/models/category';
import { Router } from '@angular/router';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
})
export class CategoryComponent implements OnInit {

  category = new categoryModel();
  notification: boolean;
  categories: any


  constructor( private categoryService: CategoryService,
               private route: Router) { }

  ngOnInit(): void {

    this.getCategories()

  }


 async  addCategory( f ){

    this.categoryService.postCategory( this.category ).subscribe( response =>{

      console.log( response);

      console.log(this.categories);
      

      this.notification = true;

      this.getCategories()
      this.route.navigate(['/categories'])

    })

    //this.category = new categoryModel()

  }


  getCategories(){

    this.categoryService.getCategories().subscribe( (response: any) =>{

      this.categories = response.CategoriesDB

      
    })

  }


 async reload(){
    this.route.navigate(['/dashboard'])
    location.reload()
  

  }

}

